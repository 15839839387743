import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import axios from "axios";

import Login from "./views/login/Login";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // eslint-disable-next-line to ignore the next line
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "landingpage",
      component: () => import("./views/landing/LandingParent.vue"),
      children: [
        {
          path: "/",
          name: "landingmain",
          component: () => import("./views/landing/Landing.vue")
        },
        {
          path: "/organize",
          name: "landingorganize",
          component: () => import("./views/landing/Organize.vue")
        },
        {
          path: "/participate",
          name: "landingparticipate",
          component: () => import("./views/landing/Participate.vue")
        },
      ]
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/privacy-policy",
      name: "privacyPolicy",
      component: () => import("./views/privacypolicy/PrivacyPolicy.vue")
    },
    {
      path: "/terms-and-conditions",
      name: "termsAndConditions",
      component: () =>
        import("./views/termsandconditions/TermsAndConditions.vue")
    },
    {
      path: "/account/forgotpassword",
      name: "forgotpassword",
      component: () => import("./views/account/ForgotPassword.vue")
    },
    {
      path: "/account/resendconfirmation",
      name: "resendconfirmaccount",
      component: () => import("./views/account/ResendConfirmAccount.vue")
    },
    {
      path: "/account/resetPassword/:validationCode",
      name: "resetPassword",
      component: () => import("./views/account/ResetPassword.vue")
    },
    {
      path: "/account/resetPasswordsuccess/",
      name: "resetpasswordsuccess",
      component: () => import("./views/account/ResetPasswordSuccess.vue")
    },
    {
      path: "/account/activate/:validationCode",
      name: "confirmaccount",
      component: () => import("./views/account/ConfirmAccount.vue")
    },
    {
      path: "/register",
      name: "register",
      component: () => import("./views/register/Register.vue")
    },
    {
      path: "/register/success",
      name: "registrationsuccess",
      component: () => import("./views/register/RegisterSuccess.vue")
    },
    {
      path: "/app",
      name: "app",
      component: () => import("./App.vue"),
      children: [
        {
          path: "/tournament/new",
          name: "newPlayerTournament",
          meta: { requiresAuth: true },
          component: () =>
            import("./views/tournament/createoredit/CreateOrEdit.vue")
        },
        {
          path: "/tournament/edit/:tournamentId",
          name: "editTournament",
          component: () =>
            import("./views/tournament/createoredit/CreateOrEdit.vue")
        },
        {
          path: "/tournament/:tournamentId",
          component: () => import("./views/tournament/Tournament.vue"),
          children: [
            {
              path: "/tournament/:tournamentId/brackets",
              name: "tournamentBrackets",
              component: () => import("./views/brackets/Brackets.vue")
            },
            {
              path: "/tournament/:tournamentId/calendar",
              name: "tournamentCalendar",
              component: () => import("./views/tournament/Calendar.vue")
            },
            {
              path: "/tournament/:tournamentId/photo-gallery",
              name: "tournamentPhotoGallery",
              component: () =>
                import("./views/tournament/TournamentPhotoGallery.vue")
            },
            {
              path: "/tournament/:tournamentId/photo-gallery/edit",
              meta: { requiresAuth: true },
              name: "editTournamentPhotoGallery",
              component: () =>
                import("./views/tournament/EditTournamentPhotoGallery.vue")
            },
            {
              path: "/tournament/:tournamentId/photo-gallery/upload",
              meta: { requiresAuth: true },
              name: "uploadTournamentPhotoGallery",
              component: () =>
                import("./views/tournament/UploadTournamentPhotoGallery.vue")
            }
          ]
        },

        {
          path: "/tournament/:tournamentId/bracket/new",
          meta: { requiresAuth: true },
          component: () =>
            import("./views/bracket/createoredit/CreateOrEdit.vue")
        },
        {
          path: "/tournament/:tournamentId/bracket/edit/:bracketId",
          component: () =>
            import("./views/bracket/createoredit/CreateOrEdit.vue")
        },
        {
          path: "/tournament/:tournamentId/bracket/:bracketId",
          component: () => import("./views/bracket/Bracket.vue"),
          children: [
            {
              path: "/tournament/:tournamentId/bracket/:bracketId/participants",
              name: "bracketParticipants",
              component: () => import("./views/bracket/Participants.vue")
            },
            {
              path: "/tournament/:tournamentId/bracket/:bracketId/knockout",
              name: "bracketKnockout",
              component: () => import("./views/bracket/Knockout.vue")
            },
            {
              path:
                "/tournament/:tournamentId/bracket/:bracketId/group/:groupId",
              name: "bracketGroup",
              component: () => import("./views/bracket/Group.vue")
            },
            {
              path: "/tournament/:tournamentId/bracket/:bracketId/config/group",
              meta: { requiresAuth: true },
              name: "bracketGroupConfig",
              component: () => import("./views/bracket/GroupConfiguring.vue")
            },
            {
              path:
                "/tournament/:tournamentId/bracket/:bracketId/config/knockout",
              meta: { requiresAuth: true },
              name: "bracketKnockoutConfig",
              component: () => import("./views/bracket/KnockoutConfiguring.vue")
            },
            {
              path: "/tournament/:tournamentId/bracket/:bracketId/game/:gameId",
              name: "bracketGame",
              component: () =>
                import("./views/tournamentmatch/TournamentMatch.vue")
            },
            {
              path: "/tournament/:tournamentId/bracket/:bracketId/calendar",
              name: "bracketCalendar",
              component: () => import("./views/bracket/Calendar.vue")
            }
          ]
        },
        {
          path: "/team/new",
          meta: { requiresAuth: true },
          name: "newTeam",
          component: () => import("./views/team/createoredit/CreateOrEdit.vue")
        },
        {
          path: "/team/edit/:teamId",
          meta: { requiresAuth: true },
          component: () => import("./views/team/createoredit/CreateOrEdit.vue")
        },
        {
          path: "/team/:teamId",
          component: () => import("./views/team/Team.vue"),
          children: [
            {
              path: "/team/:teamId/members",
              name: "teamMembers",
              component: () => import("./views/team/Members.vue")
            },
            {
              path: "/team/:teamId/stats",
              name: "teamStats",
              component: () => import("./views/team/Stats.vue")
            }
          ]
        },
        {
          path: "/profile/:profileId",
          component: () => import("./views/profile/Profile.vue"),
          children: [
            {
              path: "/profile/:profileId/next-matches",
              name: "playerProfileFutureGames",
              component: () => import("./views/profile/FutureGames.vue")
            },
            {
              path: "/profile/:profileId/tournaments/:sportId",
              name: "playerEventsTimeline",
              component: () => import("./views/profile/PlayerEventsTimeline.vue")
            },
            {
              path: "/profile/:profileId/organizations",
              name: "playerOrganizations",
              meta: { requiresAuth: true },
              component: () => import("./views/profile/MyOrganizations.vue")
            },
            {
              path: "/profile/:profileId/venues",
              name: "playerVenues",
              meta: { requiresAuth: true },
              component: () => import("./views/profile/MyVenues.vue")
            },
            {
              path: "/profile/:profileId/teams",
              name: "playerTeams",
              meta: { requiresAuth: true },
              component: () => import("./views/profile/MyTeams.vue")
            },
            {
              path: "/profile/:profileId/next-tournaments",
              name: "playerNextTournaments",
              meta: { requiresAuth: true },
              component: () => import("./views/profile/NextTournaments.vue")
            },
            {
              path: "/profile/:profileId/managed-tournaments",
              name: "playerManagedTournaments",
              meta: { requiresAuth: true },
              component: () => import("./views/profile/ManagedTournaments.vue")
            },
            {
              path: "/profile/:profileId/photo-gallery",
              name: "playerPhotoGallery",
              component: () => import("./views/profile/PlayerPhotoGallery.vue")
            },
            {
              path: "/profile/:profileId/settings",
              name: "playerSettings",
              meta: { requiresAuth: true },
              component: () => import("./views/profile/PlayerSettings.vue")
            },
            {
              path: "/profile/:profileId/sport/:sportId",
              name: "playerProfileSport",
              meta: { requiresAuth: true },
              component: () => import("./views/profile/PlayerSport.vue")
            },
            {
              path: "/profile/:profileId/phone",
              name: "playerProfilePhone",
              meta: { requiresAuth: true },
              component: () => import("./views/profile/PlayerPhone.vue")
            },
            {
              path: "/profile/:profileId/welcome",
              name: "playerProfileWelcome",
              meta: { requiresAuth: true },
              component: () => import("./views/profile/Welcome.vue")
            },
          ]
        },
        {
          path: "/organization/new",
          meta: { requiresAuth: true },
          name: "newClub",
          component: () => import("./views/club/createoredit/CreateOrEdit.vue")
        },
        {
          path: "/organization/edit/:clubId",
          meta: { requiresAuth: true },
          name: "editClub",
          component: () => import("./views/club/createoredit/CreateOrEdit.vue")
        },
        {
          path: "/organization/:clubId",
          component: () => import("./views/club/Club.vue"),
          children: [
            {
              path: "/organization/:clubId/members",
              name: "clubMembers",
              component: () => import("./views/club/Members.vue")
            },
            {
              path: "/organization/:clubId/sports",
              name: "clubSports",
              component: () => import("./views/club/Sports.vue")
            },
            {
              path: "/organization/:clubId/sports/new",
              name: "newClubSport",
              component: () => import("./views/club/NewClubSport.vue")
            },
            {
              path: "/organization/:clubId/tournaments",
              name: "clubTournaments",
              component: () => import("./views/club/Tournaments.vue")
            },
            {
              path: "/organization/:clubId/venues",
              name: "clubVenues",
              component: () => import("./views/club/Venues.vue")
            }
          ]
        },
        {
          path: "/organization/:clubId/tournament/new",
          meta: { requiresAuth: true },
          name: "newClubTournament",
          component: () =>
            import("./views/tournament/createoredit/CreateOrEdit.vue")
        },
        {
          path: "/organization/:clubId/venue/new",
          meta: { requiresAuth: true },
          name: "newClubVenue",
          component: () => import("./views/venue/createoredit/CreateOrEdit.vue")
        },
        {
          path: "/venue/new",
          meta: { requiresAuth: true },
          name: "newVenue",
          component: () => import("./views/venue/createoredit/CreateOrEdit.vue")
        },
        {
          path: "/venue/edit/:venueId",
          meta: { requiresAuth: true },
          name: "editVenue",
          component: () => import("./views/venue/createoredit/CreateOrEdit.vue")
        },
        {
          path: "/venue/:venueId",
          component: () => import("./views/venue/Venue.vue"),
          children: [
            {
              path: "/venue/:venueId/fields",
              name: "venueFields",
              component: () => import("./views/venue/Fields.vue")
            },
            {
              path: "/venue/:venueId/field/new",
              meta: { requiresAuth: true },
              name: "newVenueField",
              component: () =>
                import("./views/venue/createoreditfield/CreateOrEdit.vue")
            },
            {
              path: "/venue/:venueId/edit/:fieldId",
              name: "editVenueField",
              component: () =>
                import("./views/venue/createoreditfield/CreateOrEdit.vue")
            }
          ]
        },
        {
          path: "/tournaments",
          name: "tournaments",
          component: () => import("./views/tournaments/Tournaments.vue")
        },
        {
          path: "/organizations",
          name: "clubs",
          component: () => import("./views/clubs/Clubs.vue")
        },
        {
          path: "/venues",
          name: "venues",
          component: () => import("./views/venues/Venues.vue")
        },
        {
          path: "/teams",
          name: "teams",
          component: () => import("./views/teams/Teams.vue")
        },
        {
          path: "/teams/rankings/sport/:sportId",
          name: "teamSportRankings",
          component: () => import("./views/teams/TeamSportRankings.vue")
        },
        {
          path: "/teams/rankings/sport/:sportId/level/:levelId",
          name: "teamSportLevelRankings",
          component: () => import("./views/teams/TeamSportLevelRankings.vue")
        },
        {
          path: "/players",
          name: "players",
          component: () => import("./views/players/Players.vue")
        },
        {
          path: "/players/rankings/sport/:sportId",
          name: "playerSportRankings",
          component: () => import("./views/players/PlayerSportRankings.vue")
        },
        {
          path: "/players/rankings/sport/:sportId/level/:levelId",
          name: "playerSportLevelRankings",
          component: () =>
            import("./views/players/PlayerSportLevelRankings.vue")
        }
      ]
    },
    {
      path: "/serverdown",
      name: "serverdown",
      component: () => import("./views/serverdown/ServerDown.vue")
    },
    {
      path: "*",
      name: "notFound",
      component: () => import("./views/pagenotfound/PageNotFound.vue")
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (!store.getters["authentication/isAuthenticated"] && localStorage.getItem('should-remember-token')) {
    let persistentToken = localStorage.getItem('persistent-token');
    var instance = axios.create({
      baseURL: process.env.VUE_APP_APIURL
    });
    instance.post(process.env.VUE_APP_APIURL + "/token/refresh", { id: persistentToken, rememberMe: true }).then(async (response) => {
      let refreshTokenData = response.data;
      if (refreshTokenData) {
        await store.dispatch("authentication/authenticate", refreshTokenData)
        store.commit("language/setSelectedLanguage", refreshTokenData.languageCode);
      }
    })
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters["authentication/isAuthenticated"]) {
      let rememberMeIsChecked = localStorage.getItem('should-remember-token');
      if (rememberMeIsChecked) {
        next();
      } else {
        next({
          path: `/login?redirect=${to.fullPath}`
        });
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
