import eventBus from "./eventBus";
import eventBusMessages from "./eventBusMessages";

var inProgress = false;
var ongoingActions = [];

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export default {
  start: function() {
    if (!inProgress) {
      inProgress = true;
      eventBus.$emit(eventBusMessages.header.PROGRESS, true);
    }
    let actionID = uuidv4();
    ongoingActions.push(actionID);
    return actionID;
  },
  stop: function(actionID) {
    ongoingActions.splice(ongoingActions.indexOf(actionID), 1);
    if (ongoingActions.length === 0) {
      inProgress = false;
      eventBus.$emit(eventBusMessages.header.PROGRESS, false);
    }
  }
};
