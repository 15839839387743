<template>
  <v-btn
    :loading="loading"
    color="white elevation-0"
    large
    @click="signInWithGoogle"
    class="google-btn account-page__btn"
  >
    <v-img class="mr-2" left width="24" max-width="24" :src="`/assets/google-icon.svg`" />
    {{ label }} Google
  </v-btn>
</template>

<script>
export default {
  name: "GoogleSignIn",
  props: {
    authCallback: {
      type: Function
    },
    loading: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    }
  },
  methods: {
    signInWithGoogle() {
      this.authCallback();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import './../styles/components/account-page/google-signin';
</style>
