const state = {
  groupNameWithPoints: ""
};

const getters = {
  groupNameWithPoints: state => {
    return state.groupNameWithPoints;
  }
};

const mutations = {
  groupNameWithPoints(state, groupNameWithPoints) {
    state.groupNameWithPoints = groupNameWithPoints;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
