import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/src/stylus/app.styl";
import "font-awesome/css/font-awesome.min.css";

Vue.use(Vuetify, {
  theme: {
    primary: "#005EB8",
    primarylight1: "#0069cc",
    primarylight2: "#0077e6",
    secondary: "#64c1ff",
    accent: "#005EB8",
    error: "#f44336",
    warning: "#D50000",
    info: "#2196f3",
    success: "#36bb5e",
    lightblue: "#0091EA",
    gold: "#F0E3D0",
    invite: "#6767e6",
    register: "#038537",
    request: "#08827a",
    waitinglist: "#AD5F00",
    red: "#ff0000"
  },
  iconfont: "fa"
});
