export default {
  app: {
    REFRESH: "app.REFRESH"
  },
  brackets: {
    REFRESH: "brackets.REFRESH",
    FILTER: "brackets.FILTER"
  },
  bracketDoubles: {
    REFRESH: "bracketDoubles.REFRESH"
  },
  bracketPlayers: {
    REFRESH: "bracketPlayers.REFRESH"
  },
  bracketTeams: {
    REFRESH: "bracketTeams.REFRESH"
  },
  teamPlayers: {
    REFRESH: "teamPlayers.REFRESH"
  },
  tournamentInfo: {
    REFRESHCATEGORY: "tournamentInfo.REFRESHCATEGORY"
  },
  bracketInfo: {
    CHANGEREGISTERSTATUS: "bracketInfo.CHANGEREGISTERSTATUS"
  },
  knockout: {
    SENDCURRENTROUNDPARTICIPANTS: "knockout.SENDCURRENTROUNDPARTICIPANTS",
    REFRESH: "knockout.REFRESH"
  },
  sidebar: {
    TOGGLE: "sidebar.TOGGLE",
    EXPANDEDITEMS: "sidebar.EXPANDEDITEMS"
  },
  bracketSidebar: {
    NAVIGATEPLAYERID: "bracketSidebar.NAVIGATEPLAYERID",
    ISADMIN: "bracketSidebar.ISADMIN"
  },
  playersSidebar: {
    UPDATECOUNT: "playersSidebar.UPDATECOUNT",
    ENABLEALL: "playerSidebar.ENABLEALL"
  },
  teamsSidebar: {
    UPDATECOUNT: "teamsSidebar.UPDATECOUNT",
    ENABLEALL: "teamsSidebar.ENABLEALL"
  },
  venuesSidebar: {
    UPDATECOUNT: "venuesSidebar.UPDATECOUNT",
    ENABLEALL: "venuesSidebar.ENABLEALL"
  },
  clubsSidebar: {
    UPDATECOUNT: "clubsSidebar.UPDATECOUNT",
    ENABLEALL: "clubsSidebar.ENABLEALL"
  },
  tournamentsSidebar: {
    UPDATECOUNT: "tournamentsSidebar.UPDATECOUNT",
    ENABLEALL: "tournamentsSidebar.ENABLEALL"
  },
  venuesLocation: {
    CONCATVENUES: "venuesLocation.CONCATVENUES",
    CLEARVENUES: "venuesLocation.CLEARVENUES"
  },
  clubsLocation: {
    CONCATCLUBS: "clubsLocation.CONCATCLUBS",
    CLEARCLUBS: "clubsLocation.CLEARCLUBS"
  },
  clubMembers: {
    REFRESH: "clubMembers.REFRESH"
  },
  players: {
    FILTER: "players.FILTER"
  },
  teams: {
    FILTER: "teams.FILTER"
  },
  venues: {
    FILTER: "venues.FILTER"
  },
  clubs: {
    FILTER: "clubs.FILTER"
  },
  tournaments: {
    FILTER: "tournaments.FILTER"
  },
  bracketCalendar: {
    FILTER: "bracketCalendar.FILTER",
    SENDFIELDS: "bracketCalendar.SENDFIELDS"
  },
  header: {
    PROGRESS: "header.PROGRESS"
  },
  profileStats: {
    SPORTCHANGED: "profileStats.SPORTCHANGED"
  },
  toast: {
    NOTIFY: "toast.NOTIFY",
    CLOSE: "toast.CLOSE"
  },
  group: {
    NAVIGATEBACK: "group.NAVIGATE.NAVIGATEBACK",
    NAVIGATEFORWARD: "group.NAVIGATEFORWARD"
  }
};
