import http from "../../utils/http";

const state = {
  sports: [],
  bracketTypes: [],
  participantTypes: {},
  genderTypes: {},
  sportLevels: {}
};

const getters = {
  playerSports: state => {
    return state.sports.filter(sport => !sport.allowsTeams);
  },
  sports: state => filterByTeamSports => {
    if (filterByTeamSports)
      return state.sports.filter(sport => sport.allowsTeams);
    return state.sports;
  },
  sport: state => sportID => {
    return state.sports.find(sport => sport.value == sportID);
  },
  bracketTypes: state => {
    return state.bracketTypes;
  },
  participantTypesBySportID: state => sportID => {
    return state.participantTypes[sportID];
  },
  participantTypeText: state => (sportID, participantTypeID) => {
    let participantType = state.participantTypes[sportID].find(type => type.value == participantTypeID);
    if (participantType) {
      return participantType.text;
    }
    return "";
  },
  genderTypesBySportID: state => sportID => {
    if (state.genderTypes[sportID]) return state.genderTypes[sportID];
    return [];
  },
  genderTypesWithoutMixedBySportID: state => sportID => {
    if (state.genderTypes[sportID])
      return state.genderTypes[sportID].filter(gender => gender.value !== 3);
    return [];
  },
  genderTypesWithoutNoRestrictionsBySportID: state => sportID => {
    if (state.genderTypes[sportID])
      return state.genderTypes[sportID].filter(gender => gender.value !== 4);
    return [];
  },
  sportLevelsBySportID: state => sportID => {
    return state.sportLevels[sportID];
  },
  sportLevelsWithoutOpenBySportID: state => sportID => {
    if (state.sportLevels[sportID])
      return state.sportLevels[sportID].filter(sport => sport.weight !== 0);
    return [];
  },
  sportText: state => sportID => {
    let sport = state.sports.find(sport => sport.value == sportID);
    if (sport) {
      return sport.text;
    }
    return "";
  },
  sportLevelText: state => (sportID, sportLevelID) => {
    let sportLevel = state.sportLevels[sportID].find(
      level => level.value == sportLevelID
    );
    if (sportLevel) {
      return sportLevel.text;
    }
    return "";
  },
  sportGameDuration: state => sportID => {
    let sport = state.sports.find(sport => sport.value == sportID);
    if (sport) {
      return sport.estimatedGameDuration;
    }
    return 30;
  },
  sportTeamMinPlayers: state => sportID => {
    let sport = state.sports.find(sport => sport.value == sportID);
    if (sport) {
      return sport.teamMinPlayers;
    }
    return 0;
  },
  sportTeamMaxPlayers: state => sportID => {
    let sport = state.sports.find(sport => sport.value == sportID);
    if (sport) {
      return sport.teamMaxPlayers;
    }
    return 0;
  }
};

const mutations = {
  setSport(state, sport) {
    state.sports.push(sport);
  },
  setBracketType(state, bracketType) {
    state.bracketTypes.push(bracketType);
  },
  setParticipantTypesBySportID(state, { sportID, participantTypes }) {
    state.participantTypes[sportID] = participantTypes;
  },
  setGenderTypesBySportID(state, { sportID, genderTypes }) {
    state.genderTypes[sportID] = genderTypes;
  },
  setSportLevelsBySportID(state, { sportID, sportLevels }) {
    state.sportLevels[sportID] = sportLevels;
  },
  clearData(state) {
    state.sports = [];
    state.bracketTypes = [];
    state.participantTypes = {};
    state.genderTypes = {};
    state.sportLevels = {};
  }
};

const helpers = {
  getTranslation(translationJson, languageCode) {
    let translation = JSON.parse(translationJson).find(
      translation => translation.CountryCode.toLowerCase() === languageCode
    );
    if (translation) {
      return translation.TranslationText;
    }
    return "Translation Unavailable";
  }
};

const actions = {
  populateSportConfig({ commit, rootState }) {
    return Promise.all([
      http.get("/sport/list"),
      http.get("/bracketType/list")
    ]).then(results => {
      new Promise(() => {
        commit("clearData");
        results[0].data.forEach(sport => {
          commit("setSport", {
            value: sport.sportID,
            text: helpers.getTranslation(
              sport.translation.translationJson,
              rootState.language.selectedLanguage
            ),
            allowsSingle: sport.allowsSingle,
            allowsDouble: sport.allowsDouble,
            allowsTeams: sport.allowsTeams,
            isMonoFormat: sport.isMonoFormat,
            estimatedGameDuration: sport.estimatedGameDuration,
            teamMinPlayers: sport.teamMinPlayers,
            teamMaxPlayers: sport.teamMaxPlayers
          });
          commit("setParticipantTypesBySportID", {
            sportID: sport.sportID,
            participantTypes: sport.participantTypes.map(participantType => {
              return {
                value: participantType.participantTypeID,
                text: helpers.getTranslation(
                  participantType.translation.translationJson,
                  rootState.language.selectedLanguage
                )
              };
            })
          });
          commit("setGenderTypesBySportID", {
            sportID: sport.sportID,
            genderTypes: sport.genderTypes.map(genderType => {
              return {
                value: genderType.genderTypeID,
                text: helpers.getTranslation(
                  genderType.translation.translationJson,
                  rootState.language.selectedLanguage
                ),
                icon:
                  genderType.genderTypeID == 1
                    ? "fas fa-mars"
                    : genderType.genderTypeID == 2
                    ? "fas fa-venus"
                    : "fas fa-venus-mars"
              };
            })
          });
          commit("setSportLevelsBySportID", {
            sportID: sport.sportID,
            sportLevels: sport.sportLevels.map(sportLevel => {
              return {
                value: sportLevel.sportLevelID,
                text: helpers.getTranslation(
                  sportLevel.translation.translationJson,
                  rootState.language.selectedLanguage
                ),
                weight: sportLevel.weight
              };
            })
          });
        });
        results[1].data.forEach(bracketType => {
          commit("setBracketType", {
            value: bracketType.bracketTypeID,
            text: helpers.getTranslation(
              bracketType.translation.translationJson,
              rootState.language.selectedLanguage
            ),
            name: bracketType.name
          });
        });
      });
    });
  },
  clearData({ commit }) {
    return new Promise(resolve => {
      resolve(commit("clearData"));
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
