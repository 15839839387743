<template>
    <div class="account-page">
        <div class="top-right link black-link">
            <slot name="top-right"></slot>
        </div>
        <v-container fluid fill-height pa-0>
            <v-layout align-center justify-center class="vh-layout">
                <v-flex md6 hidden-sm-and-down pd-0>
                    <v-layout
                            align-center
                            justify-center
                            fill-height
                            row
                            wrap
                            class="account-page__image"
                    >
                        <v-img cover src="/assets/loginpic.jpg"></v-img>
                    </v-layout>
                </v-flex>
                <v-flex md6 sm12 pd-0>
                    <v-layout align-center justify-center row wrap>
                        <v-flex xs11 sm8 md10 lg8 xl6>
                            <v-card class="elevation-0 transparent">
                                <v-card-title class="px-0 account-page__title">
                                    <div>
                                        <v-img contain width="250" src="/assets/sporteeva_blue.svg"></v-img>
                                        <div class="grey--text"><slot name="title"></slot></div>
                                    </div>
                                </v-card-title>
                                <v-card-text class="my-3 px-0" v-if="!!$slots['content']">
                                    <slot name="content"></slot>
                                </v-card-text>
                                <div class="text-center"  v-if="!!$slots['buttons']">
                                    <slot name="buttons"></slot>
                                </div>
                                <slot name="alerts"></slot>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: "BasicAccountPage"
    };
</script>

<style lang="scss">
    @import '../../styles/components/account-page/account-page';
</style>
