import axios from "axios";
import store from "../store";
import router from "../router";
import eventBus from "./eventBus";
import eventBusMessages from "./eventBusMessages";

const instance = axios.create({
  baseURL: process.env.VUE_APP_APIURL
});

instance.interceptors.request.use(
  function(config) {
    config.headers = buildHeaders();
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function(response) {
    return response;
  },
  async function(error) {
    if (error.response == undefined) {
      router.push("/serverdown");
    }

    var original_request = error.config;
    if (error.response.status == 401) {
      if (!original_request._retry) {
        original_request._retry = true;
    
        var refreshTokenData = await refreshUserToken();

        if (refreshTokenData.token) {   
          await store.dispatch("authentication/authenticate", refreshTokenData)     
          original_request.headers = buildHeaders();
          return instance.request(original_request);
        }
      }
      store.commit("authentication/logOff");
      router.push("/login");
    }

    if (
      error.response.status == 400 ||
      error.response.status == 409 ||
      error.response.status == 404
    ) {
      eventBus.$emit(eventBusMessages.toast.NOTIFY, {
        color: "error",
        messages: error.response.data
      });
      console.error(error.response.data);
    }
    if (error.response.status == 500) {
      console.error("Internal server error. Please contact administrator");
    }
    return Promise.reject(error);
  }
);

function buildHeaders() {
  var header = {};
  if (store.getters["authentication/auth_token"]) {
    header["Authorization"] =
      "Bearer " + store.getters["authentication/auth_token"];
  }

  header["Content-Type"] = "application/json";
  header["Accept-Language"] = store.getters["language/selectedLanguage"];
  return header;
}

function refreshUserToken() {
  return new Promise((resolve, reject) => {
    let refreshToken = store.getters["authentication/refresh_token"];
    
    store.commit(
      "authentication/setRefreshToken",
      "00000000-0000-0000-0000-000000000000"
    );
    
    let rememberMeIsChecked = localStorage.getItem('should-remember-token');
    if(rememberMeIsChecked){      
      let persistentToken = localStorage.getItem('persistent-token');
      
      instance
        .post(process.env.VUE_APP_APIURL + "/token/refresh", {
          id: persistentToken,
          rememberMe: true
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(errorResponse => {
          store.commit("authentication/logOff");
          router.push("/login");
          reject(errorResponse);
        });

    }else{      
      instance
      .post(process.env.VUE_APP_APIURL + "/token/refresh", {
        id: refreshToken
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(errorResponse => {
        store.commit("authentication/logOff");
        router.push("/login");
        reject(errorResponse);
      });
    }

  });
}

export default {
  get: function(url) {
    return instance.get(encodeURI(process.env.VUE_APP_APIURL + url));
  },
  post: function(url, data) {
    return instance.post(process.env.VUE_APP_APIURL + url, data);
  },
  put: function(url, data) {
    return instance.put(process.env.VUE_APP_APIURL + url, data);
  },
  patch: function(url, data) {
    return instance.patch(process.env.VUE_APP_APIURL + url, data);
  },
  delete: function(url, data) {
    return instance.delete(process.env.VUE_APP_APIURL + url, data);
  }
};
