const state = {
    isAuthenticated: false,
    firstName: '',
    lastName: '',
    auth_token: '',
    refresh_token: '',
    avatarUrl: '',
    id: 0
}

const getters = {
    isAuthenticated: (state) => { 
        return state.isAuthenticated 
    },
    firstName: (state) => { 
        return state.firstName 
    },
    lastName: (state) => { 
        return state.lastName 
    },
    auth_token: (state) => { 
        return state.auth_token 
    },
    refresh_token: (state) => {
        return state.refresh_token 
    },
    avatarUrl: (state) => {
        return state.avatarUrl
    },
    id: (state) => {
        return state.id;
    }
}

const mutations = {
    logOff(state) {
        state.isAuthenticated = false;
        state.firstName = '';
        state.lastName = '';
        state.auth_token = '';
        state.refresh_token= '';
        state.id = 0;
        
        localStorage.removeItem('should-remember-token');  
        localStorage.removeItem('persistent-token');  
        localStorage.removeItem('no'); 
    },
    setAuthenticated(state, isAuthenticated) {
        state.isAuthenticated = isAuthenticated;
    },
    setFirstName(state, firstName) {
        state.firstName = firstName;
    },
    setLastName(state, lastName) {
        state.lastName = lastName;
    },
    setAuthToken(state, auth_token) {
        state.auth_token = auth_token;
    },
    setRefreshToken(state, refreshToken){
        state.refresh_token = refreshToken;
    },
    setAvatarUrl(state, avatarUrl) {
        state.avatarUrl = avatarUrl;
    },
    setId(state, id) {
        state.id = id;
    }
}

const actions = {
    authenticate({ commit }, data) {
        new Promise(() => {
            commit('setAuthenticated', true);
            commit('setFirstName', data.firstName);
            commit('setLastName', data.lastName);
            commit('setAuthToken', data.token);
            commit('setRefreshToken', data.refreshToken);
            commit('setAvatarUrl', data.avatarUrl);
            commit('setId', data.id);
        });
    },
    logOff({ commit }) {
        return new Promise((resolve) => {
            resolve(commit('logOff'));
        })
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}