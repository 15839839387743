<template>
  <v-app>
    <router-view />
    <ToastNotification />
    <cookie-law>
      <div slot="message">
        {{ $translations.get('this_website_uses_cookies') }}
        <router-link
          class="ml-1"
          to="/terms-and-conditions"
          target="_blank"
        >{{ $translations.get('terms_and_conditions') }}</router-link>
        <router-link
          class="ml-1"
          to="/privacy-policy"
          target="_blank"
        >{{ $translations.get('our_privacy_policy') }}</router-link>
      </div>
    </cookie-law>
  </v-app>
</template>
<script>
import ToastNotification from "./components/ToastNotification/ToastNotification";
import eventBusMessages from "./utils/eventBusMessages";
export default {
  name: "Main",
  components: {
    ToastNotification,
    CookieLaw: () => import("vue-cookie-law")
  },
  created() {
    if (this.$store.getters["language/selectedLanguage"] == null && !localStorage.getItem('should-remember-token')) {
      let browserLanguage = navigator.language || navigator.userLanguage;
      let langCode = browserLanguage.split("-")[0];
      if (langCode === "ro" || langCode === "en" || langCode === "de") {
        this.$store.commit("language/setSelectedLanguage", langCode);
      } else {
        this.$store.commit("language/setSelectedLanguage", "en"); 
      } 
    }
    if (this.$store.getters["sportConfig/sports"](false).length == 0) {
      this.$store.dispatch("sportConfig/populateSportConfig").then(() => {
        this.$eventBus.$emit(eventBusMessages.app.REFERSH)
      });
    }
  }
};
</script>
