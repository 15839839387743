const state = {
  tournaments: null,
  clubs: null,
  venues: null,
  teams: null,
  players: null
};

const getters = {
  tournaments: state => {
    return state.tournaments;
  },
  clubs: state => {
    return state.clubs;
  },
  venues: state => {
    return state.venues;
  },
  teams: state => {
    return state.teams;
  },
  players: state => {
    return state.players;
  }
};

const mutations = {
  setTournaments(state, tournaments) {
    state.tournaments = tournaments;
  },
  setClubs(state, clubs) {
    state.clubs = clubs;
  },
  setVenues(state, venues) {
    state.venues = venues;
  },
  setTeams(state, teams) {
    state.teams = teams;
  },
  setPlayers(state, players) {
    state.players = players;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
