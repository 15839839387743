import store from "../store";

var translations = {
  ro: {
    configuring_groups: "Configurarea grupelor",
    confirm: "confirmă",
    group: "Grupa",
    participants: "Participanţi",
    drag: "Trage",
    the_players: "jucătorii",
    the_doubles: "echipele de dublu",
    the_teams: "echipele",
    change_groups_zone: "în această zonă pentru a schimba grupele",
    points: "Puncte",
    wins: "Victorii",
    wins_abbreviation: "V",
    drawns: "Egaluri",
    drawns_abbreviation: "E",
    losses: "înfrangeri",
    losses_abbreviation: "Î",
    goals_for: "Goluri marcate",
    goals_for_abbreviation: "GM",
    goals_against: "Goluri primite",
    goals_against_abbreviation: "GP",
    goals_difference: "Diferenţă de goluri",
    goals_difference_abbreviation: "DG",
    points_for: "Puncte câştigate",
    points_for_abbreviation: "PC",
    points_against: "Puncte pierdute",
    points_against_abbreviation: "PP",
    points_difference: "Diferenţă de puncte",
    points_difference_abbreviation: "DP",
    sets_for: "Seturi câştigate",
    sets_for_abbreviation: "SC",
    sets_against: "Seturi pierdute",
    sets_against_abbreviation: "SP",
    sets_difference: "Diferenţă de seturi",
    sets_difference_abbreviation: "DS",
    games_for: "Game-uri câştigate",
    games_for_abbreviation: "GC",
    games_against: "Game-uri pierdute",
    games_against_abbreviation: "GP",
    games_difference: "Diferenţă de game-uri",
    games_difference_abbreviation: "DG",
    standing: "Clasament",
    matches: "Meciuri",
    points_downcase: "puncte",
    invite: "Invită",
    send_invites_multiple_players: "Trimite invitaţii la mai mulţi jucători",
    select_players_to_invite: "Selectează ce jucatori să inviţi",
    send_invites_multiple_teams: "Trimite invitaţii la mai multe echipe",
    search_team: "Caută echipă",
    configuring_knockout: "Configurare eliminatoriu",
    drag_area: "Zonă intermediară",
    first_round: "Prima rundă",
    long_press: "(Ţine apăsat)",
    change_matches_zone: "în această zonă pentru a schimba meciurile",
    current_round: "Runda curentă",
    quarter_final: "Sferturi de Finală",
    semi_final: "Semi Finală",
    final: "Finală",
    winner: "Campion",
    register: "Înscrie-te",
    no_teams_or_gender:
      "Nu ai echipe la acest nivel sau sexul tabloului este restricţionat",
    no_level_configured_for_sport:
      "Nu exista nivel configurat pentru acest sport",
    gender_doesnt_match_bracket: "Sexul tabloului este restricţionat",
    level_too_low: "Nivelul tău e prea scăzut",
    level_too_high: "Nivelul tău e prea ridicat",
    waiting: "În aşteptare",
    invited: "Invitat",
    registered: "Înregistrat",
    reject: "Refuză",
    accept: "Acceptă",
    accept_invite_and_participate_with: "Acceptă invitaţia şi participă cu",
    waiting_for_confirmation: "Aştepţi confirmare de la",
    administrator_invite: "Ai fost invitat să participi la acest tablou",
    you_were_invited_by: "Ai fost invitat de către",
    multiple_bracket_invites:
      "Ai fost invitat să participi la acest tablou. Alege cum ai dori să participi",
    you_are_invited_to_participate: "Esti invitat să participi",
    you_are_invited_with_your_team: "Eşti invitat să participi cu echipa ta",
    to_register_for_double_bracket:
      "Pentru a te înregistra pe un tablou de dublu, e nevoie să faci echipă cu un alt jucător",
    of_same_level_or_below: "de acelaşi nivel sau un nivel mai scăzut",
    select_your_partner: "Selectează-ti partenerul",
    partner: "Partener",
    retire: "Retrage-te",
    you_are_registered: "Eşti înregistrat",
    with_downcase: "cu",
    you_are_on_waiting_list:
      "Eşti pe lista de aşteptare. Dacă suficienţi participanţi înregistraţi se vor retrage sau mărimea tabloului va fi mărită atunci vei fi automat înregistrat",
    to_regiser_for_team_bracket:
      "Pentru a te înregistra pe un tablou de echipă e nevoie sa-ţi selectezi una din echipele tale",
    your_teams: "Echipele tale",
    no_teams_for_this_bracket:
      "Nu deţi echipe pentru această configuraţie de tablou",
    requested: "Solicitat",
    you_requested_to_join: "Ai solicitat să te înscrii",
    revoke: "Revocă",
    format: "Format",
    registration_fee: "Taxă de participare",
    free: "GRATUIT",
    per_person: "/persoană",
    mark_as_reviewed: "Marchează ca și revizuit",
    edit: "Editează",
    delete: "Șterge",
    male: "Bărbat",
    female: "Femeie",
    undefined: "Nedefinit",
    level_caps: "NIVEL",
    rank_caps: "CLASAMENT",
    kick_from_bracket: "Exclude-l de pe tablou",
    cancel_invitation: "Anulează invitaţia",
    accept_request: "Acceptă solicitarea",
    decline_request: "Refuză solicitarea",
    checked_in_action: "S-a prezentat",
    not_defined: "Nedefinit",
    energy_caps: "ENERGIE",
    from: "de la",
    per_month: "/lună",
    select: "Selectează",
    send_club_invites_to_multiple:
      "Trimite invitaţii în organizație la mai mulţi jucători",
    today: "Azi",
    months: [
      "Ianuarie",
      "Februarie",
      "Martie",
      "Aprilie",
      "Mai",
      "Iunie",
      "Iuluie",
      "August",
      "Septembrie",
      "Octombrie",
      "Noiembrie",
      "Decembrie"
    ],
    weekdays: [
      "Duminică",
      "Luni",
      "Marți",
      "Miercuri",
      "Joi",
      "Vineri",
      "Sâmbătă"
    ],
    latest_results: "Ultimele rezultate",
    jan: "Ian",
    feb: "Feb",
    mar: "Mar",
    apr: "Apr",
    may: "Mai",
    jun: "Iun",
    jul: "Iul",
    aug: "Aug",
    sep: "Sep",
    whats_next: "Ce urmează?",
    create_tournament: "Creează turneu",
    create_team: "Creează echipă",
    create_venue: "Creează arenă",
    create_club: "Creează organizație",
    tournament_matches_next_7days:
      "Meciurile tale de turneu în urmatoarele 7 zile",
    member: "membru",
    members: "membrii",
    sports_caps: "SPORTURI",
    sport_caps: "SPORT",
    more: "în plus",
    owner: "Organizator",
    requested_downcase: "au solicitat",
    location: "Locație",
    location_address: "Adresa locației",
    tournament_dates: "Perioada turneului",
    public: "Public",
    private: "Privat",
    upcoming_tournaments:
      "Turneele viitoare din orașul tău pe baza sporturilor tale",
    no_tournaments_your_area:
      "Pentru moment nu există turnee în această zonă. Te rugam sa verifici dacă ți-ai setat orașul in",
    profile: "profil",
    start_using_sporteeva: "Începe sa folosești Sporteeva",
    first_thing: "În primul rând",
    first_need_to_unlock_a_sport:
      "Pentru a folosi Sporteeva e nevoie sa deblochezi un sport. Nu-ți face griji primul sport este din partea casei, nu e nevoie să plătești nimic să folosești Sporteeva. Nu ezita, alege un sport!",
    here_are_some_tips: "Câteva sfaturi pentru a incepe",
    global_search_tool_tip:
      "Sunt diferite moduri de a găsi informații pe Sporteeva. Pentru lucruri generale cum ar fi turnee, jucători, echipe te sfătuim să folosești căutarea globală din dreapta sus.",
    main_navigation_tip:
      "Dacă te interesează ceva mai specific, poti folosi navigarea principală, sau să dai click pe una din opțiunile de mai jos:",
    start_a_tournament: "Începe un turneu",
    create_a_club: "Creeză o organizație",
    create_a_team: "Creează o echipă",
    start_a_match: "Începe un meci",
    register_a_venue: "Înregistrează o arenă",
    find_a_specific_player: "Găsește un jucător anume",
    welcome_to_your_dashboard: "Bun venit pe Dashboard-ul tău",
    hi: "Salut",
    as_you_get_involved:
      "Pe măsură ce te vei implica în activitățile susținute pe Sporteeva, o să vezi noi informații în această zonă",
    example_you_might_see_here: "Câteva exemple legate de ce o să vezi aici:",
    your_upcoming_match_schedule: "Orarul meciurilor tale viitoare",
    upcoming_events: "Următoarele evenimente care te-ar putea interesa",
    recent_results: "Ruzultatele tale recente",
    end_group_stage: "Închide faza grupelor",
    since: "Din",
    add_sport: "Adaugă sport",
    join: "Alătură-te",
    member_uppercase: "Membru",
    you_are_a_member_of_this_club: "Eşti membru al acestei organizații",
    leave: "Părăseşte-l",
    you_are_invited_to_this_club: "Eşti invitat să te alături acestui club",
    you_have_requested_to_join_this_club:
      "Ai solicitat să te alături acestei organizații",
    sport: "Sport",
    level: "Nivel",
    you_are_a_member_of_this_team: "Eşti membru al acestei echipe",
    you_are_invited_to_this_team: "Eşti invitat să te alături acestei echipe",
    you_have_requested_to_join_this_team:
      "Ai solicitat să te alături acestei echipe",
    public_tournament_downcase: "turneu public",
    private_tournament_downcase: "turneu privat",
    no_of_courts: "Nr. de terenuri",
    registration_ends: "Limită înscrieri",
    end_tournament: "Închide turneul",
    end_registration: "Închide înscrierile",
    publish: "Publică",
    add_bracket: "Adaugă tablou",
    create_field: "Creează teren",
    edit_field: "Editează terenul",
    tournaments: "Turnee",
    clubs: "Organizații",
    venues: "Arene",
    venue: "Arena",
    search_venue: "Caută arenă",
    teams: "Echipe",
    players: "Jucători",
    type_in_to_search: "Scrie pentru a căuta...",
    add: "Adaugă",
    single_rank_caps: "CLASAMENT SIMPLU",
    double_rank_caps: "CLASAMENT DUBLU",
    tournaments_downcase: "turnee",
    team_level_caps: "NIVEL ECHIPĂ",
    team_rank_caps: "CLASAMENT ECHIPĂ",
    round: "Runda",
    score: "Scorul",
    opponents: "Adversarii",
    opponent: "Adversar",
    registration_date: "Data Înregistrării",
    double_tournaments: "Turnee de dublu",
    double_matches: "Meciuri de dublu",
    single_tournaments: "Turnee de simplu",
    single_matches: "Meciuri de simplu",
    tournaments_this_year: "Turnee anul acesta",
    titles_per_finals: "Titluri / Finale",
    platinium_champion: "Campion Platinium",
    platinium_finalist: "Finalist Platinium",
    gold_champion: "Campion Gold",
    gold_finalist: "Finalist Gold",
    silver_champion: "Campion Silver",
    silver_finalist: "Finalsit Silver",
    bronze_champion: "Campion Bronze",
    bronze_finalist: "Fianlist Bronze",
    rank: "Clasament",
    player: "Jucător",
    team: "Echipă",
    club: "Organizație",
    rank_move: "Variaţie",
    selected: "selectate",
    fields: "Terenuri",
    group_stage: "Faza grupelor",
    knockout_stage: "Faza eliminatorie",
    calendar: "Calendar",
    gender: "Sex",
    type: "Tip",
    brackets: "Tablouri",
    members_uppercase: "Membrii",
    sports: "Sporturi",
    search_results: "Rezultate căutare",
    name: "Nume",
    country: "Ţară",
    county: "Judeţ",
    city: "Oraş",
    dashboard: "Dashboard",
    my_active_tournaments: "Turneele mele active",
    drafts: "Ciorne",
    invites: "Invitaţii",
    join_requests: "Solicitări înscriere",
    waiting_list: "Lista de aşteptare",
    public_plural: "Publice",
    private_plural: "Private",
    administrating: "Administrate",
    my_teams: "Echipele mele",
    team_owner: "Căpitan",
    my_clubs: "Organizațiile mele",
    manager: "Manager",
    my_venues: "Arenele mele",
    profile_uppercase: "Profil",
    rankings: "Clasamente",
    stats: "Statistici",
    dates: "Perioada",
    to: "până la",
    category: "Categorie",
    status: "Status",
    open_registration: "Înscrieri deschise",
    in_progress: "În desfășurare",
    ended: "Încheiat",
    send_team_invites_to_multiple_players:
      "Trimite invitații de echipă la mai mulți jucători",
    wins_caps: "VICTORII",
    losses_caps: "ÎNFRÂNGERI",
    ties_caps: "EGALURI",
    add_new_announcement_caps: "ADAUGĂ ANUNȚ NOU",
    cancel: "anulează",
    announcements: "Anunțuri",
    announcement: "Anunț",
    select_severity: "Selectează severitatea",
    information: "Informație",
    warning: "Avertisment",
    cancelation: "Anulare",
    clubs_overview: "Sumarul organizaților",
    venues_overview: "Sumarul arenelor",
    sign_in_with: "Înregistrează-te cu",
    log_in: "Loghează-te",
    log_in_with: "Loghează-te cu",
    log_out: "Deloghează-te",
    draft: "Ciornă",
    reviewed: "Revizuit",
    groups_in_progress: "Grupele în desfășurare",
    knockout_in_progress: "Eliminatoriul în deșfășurare",
    join_requested: "Solicitare înscriere",
    checked_in_status: "Prezent",
    homeButton: "Înapoi la pagina principală",
    backButton: "Înapoi",
    new_password: "Parolă nouă",
    email: "Email",
    emailRule1: "Email-ul este obligatoriu",
    emailRule2: "Formatul email-ului este incorect",
    passwordRule1: "Parola este obligatorie",
    passwordRule2: "Parola este prea slabă",
    confirmAccount_title: "Confirmare cont",
    confirmAccount_loadingMessage: "Se validează linkul, vă rugăm așteptați",
    confirmAccount_successMessage:
      "Contul dumneavoastră a fost confirmat cu succes. Veți fi redirecționat la pagina de login în curând.",
    confirmAccount_expiredLinkMessage: "Acest link de confirmare a expirat.",
    confirmAccount_linkNotFoundMessage:
      "Acest link de confirmare nu este valid.",
    confirmAccount_errorOccurredMessage:
      "Am intâmpinat o eroare la validarea linkului. Vă rugăm încercați mai tarziu.",
    forgotPassword_title: "Resetare Parolă",
    forgotPassword_sendPasswordConfirm: "Resetează parola",
    forgotPassword_resetPasswordHint:
      "Pentru a primi linkul de resetare parolă, introduceți adresa de email asociată contului dumneavoastră",
    forgotPassword_sendButton: "Trimite",
    forgotPassword_successMessage:
      "În urmatoarele minute, va fi trimis un email cu linkul de resetare parolă la emailul dumneavoastră. În curand veți fi redirecționat la pagina de inceput.",
    forgotPassword_notFoundMessage:
      "Nu există niciun utilizator cu acest email.",
    forgotPassword_errorOccurredMessage:
      "Am întâmpinat o eroare la validarea emailului. Vă rugăm încercați mai tarziu.",
    resendConfirmation_title: "Retrimitere confirmare e-mail",
    resendConfirmation_sendPasswordConfirm: "Retrimitere confirmare e-mail",
    resendConfirmation_resetPasswordHint:
      "Pentru a reprimi linkul de activare cont, introduceți adresa de email asociată contului dumneavoastră",
    resendConfirmation_sendButton: "Trimite",
    resendConfirmation_successMessage:
      "În următoarele minute, va fi trimis un email cu linkul de confirmare cont la emailul dumneavoastră. În curand veți fi redirecționat la pagina de inceput.",
    resendConfirmation_notFoundMessage:
      "Nu există niciun utilizator cu acest email.",
    resendConfirmation_errorOccurredMessage:
      "Am intâmpinat o eroare la validarea emailului. Vă rugăm încercați mai tarziu.",
    resetPassword_title: "Resetare Parolă",
    resetPassword_loadingMessage: "Se validează datele, vă rugăm așteptați",
    resetPassword_successMessage:
      "Codul a fost validat cu succes. Alegeți parola noua.",
    resetPassword_expiredLinkMessage: "Acest link de confirmare a expirat.",
    resetPassword_linkNotFoundMessage:
      "Acest link de confirmare nu este valid.",
    resetPassword_errorOccurredMessage:
      "Am întâmpinat o eroare la validarea linkului. Vă rugam încercați mai tarziu.",
    resetPasswordSuccess_title: "Parola a fost resetată cu succes.",
    resetPasswordSuccess_text:
      "Acum vă puteți conecta folosind noua parolă. Veți fi redirecționat în curând",
    sign_up: "Înregistrează-te",
    registerIntro: "Vă rugăm să completați pentru a vă creea cont",
    firstName: "Prenume",
    lastName: "Nume",
    password: "Parola",
    confirmPassword: "Confirmați parola",
    agreement: "Sunt de acord cu ",
    termAndCond: "politica de confidențialitate",
    firstNameRule: "Prenumele este obligatoriu",
    lastNameRule: "Numele este obligatoriu",
    nameRule: "Numele este obligatoriu",
    confirmPasswordRule: "Parolele nu coincid",
    agreementRule: "Trebuie să fiți de acord pentru a continua!",
    genderRule: "Sexul este obligatoriu",
    strong: "Puternică",
    medium: "Medie",
    welcomeTo: "Bun venit la",
    registerSuccessful_title: "Înregistrarea a fost efectuată cu succes!",
    registerSuccessful_text:
      "Contul dumneavoastră a fost confirmat cu succes! Pentru a vă putea loga, trebuie întâi să vă confirmați contul. Veți primi un email cu linku de confirmare în curând.",
    confirmAccountTitle: "Confirmare Cont",
    confirmAccountSuccess:
      "Contul dumneavoastră a fost confirmat cu succes. Veți fi redirecționat la pagina de login în curând.",
    confirmAccountExpiredLink: "Acest link de confirmare a expirat.",
    confirmAccountNotFoundLink: "Acest link de confirmare nu este valid.",
    create_bracket: "Creează tablou",
    edit_bracket: "Editează tablou",
    bracket_details: "Detalii tablou",
    save_changes: "Salvează modificările",
    participant_type: "Tipul de participant",
    bracket_type: "Tip tablou",
    expected_size: "Numărul de participanți",
    number_of_groups: "Numărul de grupe",
    group_size: "Mărimea grupei",
    qualified_per_group: "Numărul de calificați din grupă",
    estimated_game_duration: "Durata estimativă a unui meci (minute)",
    unplanned_games: "Meciuri neplanificate",
    unplanned_games_info:
      "Dați click pe celule pentru a popula automat data, ora si terenul",
    date: "Data",
    time: "Ora",
    field: "Teren",
    select_field: "Selecteaza terenul",
    tournament_venue_has_no_fields_configured:
      "Arena turneului nu are terenuri configurate pentru acest sport",
    club_details: "Detalii organizație",
    club_address: "Adresa organizației",
    club_owner: "Manager",
    administrator: "Administrator",
    active: "Activ",
    promote_to_administrator: "Promovează la administrator",
    kick_from_club: "Exclude-l din organizație",
    revoke_administration: "Revocă drepturile de administrator",
    bronze_club_package_sub_tip1:
      "Bronze este primul nivel de sport pentru o organizație",
    bronze_club_package_sub_tip2: "Puteți organiza două turnee Bronze pe lună",
    bronze_club_package_sub_tip3:
      "Această subscripție oferă si posibilitatea de a creea două turnee private pe lună",
    bronze_club_package_tip:
      "Acest nivel este potrivit pentru organizațiile noi pe platformă",
    silver_club_package_sub_tip1:
      "Ca și Silver deja ați organizat câteva turnee și doriti mai mult",
    silver_club_package_sub_tip2:
      "La acest nivel aveți doua turnee Silver pe lună și un număr nelimitat de turnee Bronze",
    silver_club_package_sub_tip3:
      "Această subscripție oferă si posibilitatea de a creea șase turnee private pe lună",
    silver_club_package_tip:
      "Acest nivel este potrivit organizațiilor cu experiență în organizarea de turnee si care vor să crească si mai mult",
    gold_club_package_sub_tip1:
      "Gold arată ca sunteți un orgnizator de turnee experimentat",
    gold_club_package_sub_tip2:
      "La acest nivel aveți incluse două turnee Gold pe lună și un număr nelimitat de turnee Silver si Bronze. De asemenea aveți incluse o mulțime de turnee private",
    gold_club_package_sub_tip3:
      "Gold semnifică că sunteți de încredere și apreciați de jucători.",
    gold_club_package_tip:
      "Potrivit pentru organizațiile care oferă condiții și experiențe deosebite jucătorilor",
    platinium_club_package_sub_tip1:
      "Ești Platinum, apex-ul organizatorilor de turnee",
    platinium_club_package_sub_tip2:
      "Jucătorii se așteaptă la cele mai bune servicii și dumneavoastră le oferiți",
    platinium_club_package_sub_tip3: "La acest nivel nu există restricții",
    platinium_club_package_tip:
      "Recomandat pentru organizațiile care dețin infrastructura pentru a organiza competiții de top",
    added_caps: "ADĂUGAT",
    expires_caps: "EXPIRĂ",
    creator: "Creator",
    field_downcase: "teren",
    fields_downcase: "terenuri",
    tournaments_you_are_administrating: "Turnee pe care le administrezi",
    my_club_invites: "Invitațiile mele din partea unor organizații",
    clubs_you_manage: "Organizațiile pe care le manageriezi",
    clubs_you_are_member_of: "Organizațiile în care ești membru",
    tournaments_you_requested_to_join:
      "Turneele la care ai solicitat să te înscrii",
    your_private_active_tournaments: "Turneele tale private și active",
    your_public_active_tournaments: "Turneele tale publice și active",
    my_team_invites: "Invitațiile mele din partea unor echipe",
    teams_you_are_member_of: "Echipele din care fac parte",
    my_owned_teams: "Echipele pe care le deți",
    your_tournament_drafts: "Turneele tale nepublicate",
    your_tournament_invites: "Turneele la care ești invitat",
    tournaments_you_are_on_waiting_list:
      "Turneele la care ești pe lista de așteptare",
    login: "Loghează-te",
    loginIntro: "Loghează-te folosindu-ți credențialele",
    loginDataInvalid: "Emailul sau parola sunt incorecte",
    accountNotConfirmed: "Trebuie să-ți validezi emailul înainte să te loghezi",
    userNotFound: "Nu există cont pentru acest email",
    resendConfirmationEmail: "Retrimiteți email de confirmare?",
    dont_have_an_account: "Nu ai cont?",
    forgot_password: "Ai uitat parola?",
    remember_me: "Păstrează-mă conectat",
    single: "Simplu",
    doubles: "Dublu",
    single_caps: "SIMPLU",
    doubles_caps: "DUBLU",
    players_caps: "JUCĂTORI",
    ranked_players: "jucători clasați",
    free_player_package_sub_tip1: "Puteți alege 3 sporturi",
    free_player_package_sub_tip2: "Puteți creea 1 echipă",
    free_player_package_sub_tip3: "Puteți participa la 1 turneu public pe lună",
    free_player_package_sub_tip4:
      "Puteți creea 1 turneu privat cu prietenii pe lună",
    free_player_package_tip: "Concurează, joacă și testează platforma",
    weekend_warrior_player_package_sub_tip1: "Puteți alege 3 sporturi",
    weekend_warrior_player_package_sub_tip2:
      "Puteți participa la 4 turnee pe lună",
    weekend_warrior_player_package_sub_tip3:
      "Puteți creea 1 turneu public Bronze pe lună",
    weekend_warrior_player_package_sub_tip4:
      "Puteți creea 2 turnee private cu prietenii pe lună",
    weekend_warrior_player_package_tip:
      "Sunteți un jucător consistent și un organizator ocazional",
    semi_pro_player_package_sub_tip1: "Puteți alege 5 sporturi",
    semi_pro_player_package_sub_tip2: "Puteți participa la 8 turnee pe lună",
    semi_pro_player_package_sub_tip3:
      "Puteți creea 1 turneu public Silver și 2 turnee Bronze pe lună",
    semi_pro_player_package_sub_tip4:
      "Puteți creea 4 turnee private cu prietenii pe lună",
    semi_pro_player_package_tip:
      "Jucător pasionat, organizator de turnee, competitiv cu adevărat",
    select_your_level: "Selectează-ți nivelul",
    select_your_level_tip:
      "Selectează-ti cu atenție nivelul, în caz contrar dezechilibrul ar face ca adversarii tăi să se simtă incomfortabil",
    already_hanve_an_account: "Ai deja cont?",
    if_you_have_any_questions_send_email:
      "Pentru orice fel de întrebare nu ezitați să ne scrieți la",
    date_of_birth: "Data nașterii",
    show_personal_data_on_my_profile: "Arată datele mele personale pe profil",
    edit_team: "Editează echipa",
    team_details: "Detalii echipă",
    no_team_sports_configured_for_profile:
      "Nu ai sporturi de echipă deblocate pe profile",
    teams_caps: "ECHIPE",
    ranked_teams: "echipe clasate",
    as: "ca și",
    edit_the_tournament: "Editează turneu",
    tournament_details: "Detalii turneu",
    registration_by_confirmation_only:
      "Înregistrare doar pe bază de confirmare",
    number_of_fields: "Numărul de terenuri",
    start_date: "Data de început",
    end_date: "Data de sfârșit",
    no_club_sports: "Nu există sporturi deblocate pentru organizația ta",
    no_player_sports: "Nu există sporturi deblocate pe profilul tău",
    stage: "Faza",
    match_date: "Data meciului",
    match_time: "Ora meciului",
    save_uppercase: "Salvează",
    cancel_uppercase: "Anulează",
    schedule: "Orar",
    sets: "Seturi",
    venue_details: "Detalii arenă",
    venue_address: "Adresa arenei",
    field_details: "Detalii teren",
    this_website_uses_cookies:
      "Acest site folosește 'cookies'. Navigând în continuare, vă exprimați acordul asupra folosirii acestora.",
    our_privacy_policy: "Politica noastră de confidenţialitate",
    you_profile_on_sporteeva: "Profilul tău pe sporteeva.com",
    settings: "Setări",
    language: "Limba",
    romanian: "Română",
    english: "Engleză",
    german: "Germană",
    update_profile_settings: "Actualizează setările de profil",
    contains: "conține",
    match: "Meci",
    language_change: "Această modificare se va reflecta la următoarea logare",
    terms_and_conditions: "Termenii și condițiile",
    social_account_not_registered: "Acest cont nu este înregistrat",
    no_sport_warning:
      "Pentru a te înscrie pe tablou e nevoie sa deblochezi acest sport. Vrei să o faci acum ?",
    yes: "Da",
    no: "Nu",
    configurating: "În configurare",
    end_configuration: "Finalizează configurarea",
    photo_gallery: "Galerie foto",
    photo_information: "Informații Foto",
    gallery_information: "Informatii Galerie",
    select_players_to_tag: "Etichetează cu jucători",
    unselect_all: "Deselectează tot",
    select_all: "Selectează tot",
    upload: "Încarcă",
    tournament: "Turneu",
    no_phone_warning: "Creatorul turneului a setat numărul de telefon ca fiind obligatoriu pentru înregistrare. Dorești să-ți verifici numărul acum?",
    registration_phone_required: "Înregistrarea necesită număr de telefon",
    update_phone_number: "Actualizează-ți numărul de telefon",
    update_phone_text: "Unele turnee necesită numărul tău de telefon pentru a te ține informat prin SMS în legatură cu modificări de ultim moment și anunțuri. Te rugăm să-ți validezi numărul de telefon. Acesta va fi folosit DOAR pentru cele menționate.",
    send_validation_code: "Trimite cod de validare",
    confirm_phone_number: "Confirmă număr de telefon",
    fill_in_code: "Introdu codul primit la",
    did_not_received_code: "Nu am primit codul prin SMS",
    message: "Mesaj",
    validation_code_invalid: "Codul de validare este invalid",
    validation_code_incomplete: "Codul de validare este incomplet",
    phone_number_invalid: "Numărul de telefon este invalid",
    country_code: "Cod de țară",
    choose_a_country: "Selectează o țară",
    phone_number: "Număr de telefon",
    example: "Exemplu :",
    anti_covid_recommendations: "Recomandări anti-COVID19",
    anti_covid_no_spectators: "Nu se recomandă prezența spectatorilor",
    anti_covid_field_change: "Se recomandă schimbarea terenului doar la set",
    anti_covid_no_locker_rooms: "Se va evita folosirea băilor și vestiarelor",
    anti_covid_presence: "Prezența la meciuri se va face pe proprie răspundere și cu asumarea de către jucător a tuturor implicațiilor ce decurg din prezența lui acolo",
    anti_covid_handshake: "După încheierea meciurilor se va evita orice formă de socializare, inclusiv traditionala strangere de mână",
    anti_covid_handwash: "Se va menține o igienă personală corespunzătoare",
    anti_covid_distancing: "Păstrați recomandările de distantare sociala",
    anti_covid_no_cash: "Plata taxelor de participare se va face pe cât posibil, prin mijloace online",
    anti_covid_bench: "Jucătorii vor petrece pauzele dintre game-uri în părți opuse ale terenului",
    trophies: "Trofee",
    your_team: "Echipa ta",
    hide_level: "Ascunde Nivel",
    show_level: "Vezi Nivel",
    hide_stats: "Ascunde Statistici",
    show_stats: "Vezi Statistici",
    tournament_winner: "Campion Turneu",
    last_round: "Ultima Rundă",
    points_gained: "Puncte Obținute",
    see_matches: "Vezi meciurile",
    hide_matches: "Ascunde meciurile",
    next_tournaments: "Următoarele turnee",
    managed_tournaments: "Turneele administrate",
    next_matches: "Următoarele meciuri",
    past_competitions: "Istoric competiții",
    average: "Media",
    select_bracket_team_players: "Selectează membrii care vor participa",
    select_a_team_to_register: "Selectați o echipă pentru a vă înscrie",
    select_min_max_team_members: "Selectați cel puțin {min} membrii și maxim {max} pentru a vă înscrie",
    no_photos_in_galery: "Momentan nu există fotografii în galerie."
  },
  en: {
    points: "Points",
    configuring_groups: "Configuring groups",
    confirm: "confirm",
    group: "Group",
    participants: "Participants",
    drag: "Drag",
    the_players: "players",
    the_doubles: "doubles",
    the_teams: "teams",
    change_groups_zone: "in this zone to change the groups",
    wins: "Wins",
    wins_abbreviation: "W",
    drawns: "Drawns",
    drawns_abbreviation: "D",
    losses: "Losses",
    losses_abbreviation: "L",
    goals_for: "Goals For",
    goals_for_abbreviation: "GF",
    goals_against: "Goals Against",
    goals_against_abbreviation: "GA",
    goals_difference: "Goals Difference",
    goals_difference_abbreviation: "GD",
    points_for: "Points for",
    points_for_abbreviation: "PF",
    points_against: "Points against",
    points_against_abbreviation: "PA",
    points_difference: "Points Difference",
    points_difference_abbreviation: "PD",
    sets_for: "Sets for",
    sets_for_abbreviation: "SF",
    sets_against: "Sets against",
    sets_against_abbreviation: "SA",
    sets_difference: "Sets Difference",
    sets_difference_abbreviation: "SD",
    games_for: "Sets for",
    games_for_abbreviation: "SF",
    games_against: "Sets against",
    games_against_abbreviation: "SA",
    games_difference: "Sets Difference",
    games_difference_abbreviation: "SD",
    standing: "Standing",
    matches: "Matches",
    points_downcase: "points",
    invite: "Invite",
    send_invites_multiple_players: "Send invites to multiple players",
    select_players_to_invite: "Select players to invite",
    send_invites_multiple_teams: "Send invites to multiple teams",
    search_team: "Search team",
    configuring_knockout: "Configuring knockout",
    drag_area: "Drag area",
    first_round: "First round",
    long_press: "(Long-Press)",
    change_matches_zone: "in this zone to change the matches",
    current_round: "Current round",
    quarter_final: "Quarter-Final",
    semi_final: "Semi-Final",
    final: "Final",
    winner: "Winner",
    register: "Register",
    no_teams_or_gender:
      "You don't have any teams at this level or the gender doesn't match",
    no_level_configured_for_sport: "No level configured for this sport",
    gender_doesnt_match_bracket: "Your gender doesn't match the bracket gender",
    level_too_low: "Your level is too low",
    level_too_high: "Your level is too high",
    waiting: "Waiting",
    invited: "Invited",
    registered: "Registered",
    reject: "Reject",
    accept: "Accept",
    accept_invite_and_participate_with: "Accept invite and participate with",
    waiting_for_confirmation: "Waiting for confirmation from",
    administrator_invite: "You've been invited to participate in this bracket",
    you_were_invited_by: "You were invited by",
    multiple_bracket_invites:
      "You've been invited to participate in this bracket. Please choose how you would like to participate",
    you_are_invited_to_participate: "You're invited to participate",
    you_are_invited_with_your_team:
      "You're invited to participate with your team",
    to_register_for_double_bracket:
      "To register for a double bracket, you need to team up with a player",
    of_same_level_or_below: "of the same level or a level below",
    select_your_partner: "Select your partner",
    partner: "Partner",
    retire: "Retire",
    you_are_registered: "You're registered",
    with_downcase: "with",
    you_are_on_waiting_list:
      "You're on the waiting list. If enough registered participants will retire or the bracket size will be expanded you will be automatically registered",
    to_regiser_for_team_bracket:
      "To register for a team bracket you need to select one of your teams",
    your_teams: "Your teams",
    no_teams_for_this_bracket:
      "You don't own teams for this bracket configuration",
    requested: "Requested",
    you_requested_to_join: "You've requested to join",
    revoke: "Revoke",
    format: "Format",
    registration_fee: "Registration Fee",
    free: "FREE",
    per_person: "/person",
    mark_as_reviewed: "Mark as reviewed",
    edit: "Edit",
    delete: "Delete",
    male: "Male",
    female: "Female",
    undefined: "Undefined",
    level_caps: "LEVEL",
    rank_caps: "RANK",
    kick_from_bracket: "Kick from bracket",
    cancel_invitation: "Cancel invitation",
    accept_request: "Accept request",
    decline_request: "Decline request",
    checked_in_action: "Checked in",
    not_defined: "Not defined",
    energy_caps: "ENERGY",
    from: "from",
    per_month: "/month",
    select: "Select",
    send_club_invites_to_multiple:
      "Send organization invites to multiple players",
    today: "Today",
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    weekdays: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    latest_results: "Latest Results",
    jan: "Jan",
    feb: "Feb",
    mar: "Mar",
    apr: "Apr",
    may: "May",
    jun: "Jun",
    jul: "Jul",
    aug: "Aug",
    sep: "Sep",
    whats_next: "What's next?",
    create_tournament: "Create tournament",
    create_team: "Create team",
    create_venue: "Create venue",
    create_club: "Create organization",
    tournament_matches_next_7days: "Your tournament matches in the next 7 days",
    member: "member",
    members: "members",
    sports_caps: "SPORTS",
    sport_caps: "SPORT",
    more: "more",
    owner: "Owner",
    requested_downcase: "requested",
    location: "Location",
    location_address: "Location address",
    tournament_dates: "Tournament Dates",
    public: "Public",
    private: "Private",
    upcoming_tournaments:
      "Upcoming tournaments in your City based on your profile sports",
    no_tournaments_your_area:
      "For the moment there are no tournaments in your Area. Also please check 'City' is configured for your",
    profile: "profile",
    start_using_sporteeva: "Start using Sporteeva",
    first_thing: "First things, first!",
    first_need_to_unlock_a_sport:
      "In order to use Sporteeva, you first need to unlock a sport. Don't worry you're first sport is on us so you won't have to pay anything for using Sporteeva. Go ahaead, pick a sport!",
    here_are_some_tips: "Here are some tips to get you started",
    global_search_tool_tip:
      "There's lots of ways to find things in Sporteeva. You can use the global search tool at the top of the interface to find just about anything in Sporteeva.",
    main_navigation_tip:
      "If you're looking for something more specific, you can use the links in the main navigation, or click one of the options below:",
    start_a_tournament: "Start a tournament",
    create_a_club: "Create a organization",
    create_a_team: "Create a team",
    start_a_match: "Start a match",
    register_a_venue: "Register a venue",
    find_a_specific_player: "Find a specific Player",
    welcome_to_your_dashboard: "Welcome to your Dashboard",
    hi: "Hi",
    as_you_get_involved:
      "As you get involved in events and organizations in Sporteeva, you'll start to see your dashboard fill up with new content",
    example_you_might_see_here:
      "Some examples of what you might see here include:",
    your_upcoming_match_schedule: "Your upcoming match schedule",
    upcoming_events: "Upcoming events that you might be interested in",
    recent_results: "Recent results",
    end_group_stage: "End group stage",
    since: "Since",
    add_sport: "Add sport",
    join: "Join",
    member_uppercase: "Member",
    you_are_a_member_of_this_club: "You're a member of this organization",
    leave: "Leave",
    you_are_invited_to_this_club: "You're invited to join this organization",
    you_have_requested_to_join_this_club:
      "You've requested to join this organization",
    sport: "Sport",
    level: "Level",
    you_are_a_member_of_this_team: "You're a member of this team",
    you_are_invited_to_this_team: "You're invited to join this team",
    you_have_requested_to_join_this_team: "You've requested to join this team",
    public_tournament_downcase: "public tournament",
    private_tournament_downcase: "private tournament",
    no_of_courts: "No. of courts",
    registration_ends: "Registration Ends",
    end_tournament: "End Tournament",
    end_registration: "End Registration",
    publish: "Publish",
    add_bracket: "Add bracket",
    create_field: "Create field",
    edit_field: "Edit field",
    tournaments: "Tournaments",
    clubs: "Organizations",
    venues: "Venues",
    venue: "Venue",
    search_venue: "Search venue",
    teams: "Teams",
    players: "Players",
    type_in_to_search: "Type in to Search...",
    add: "Add",
    single_rank_caps: "SINGLE RANK",
    double_rank_caps: "DOUBLE RANK",
    tournaments_downcase: "tournaments",
    team_level_caps: "TEAM LEVEL",
    team_rank_caps: "TEAM RANK",
    round: "Round",
    score: "Score",
    opponents: "Opponents",
    opponent: "Opponent",
    registration_date: "Registration Date",
    double_tournaments: "Double Tournaments",
    double_matches: "Double Matches",
    single_tournaments: "Single Tournaments",
    single_matches: "Single Matches",
    tournaments_this_year: "Tournaments this year",
    titles_per_finals: "Titles / Finals",
    platinium_champion: "Platinium Champion",
    platinium_finalist: "Platinium Finalist",
    gold_champion: "Gold Champion",
    gold_finalist: "Gold Finalist",
    silver_champion: "Silver Champion",
    silver_finalist: "Silver Finalist",
    bronze_champion: "Bronze Champion",
    bronze_finalist: "Bronze Finalist",
    rank: "Rank",
    player: "Player",
    team: "Team",
    club: "Organization",
    rank_move: "Move",
    selected: "selected",
    fields: "Fields",
    group_stage: "Group stage",
    knockout_stage: "Knockout stage",
    calendar: "Calendar",
    gender: "Gender",
    type: "Type",
    brackets: "Brackets",
    members_uppercase: "Members",
    sports: "Sports",
    search_results: "Search results",
    name: "Name",
    country: "Country",
    county: "County",
    city: "City",
    dashboard: "Dashboard",
    my_active_tournaments: "My active tournaments",
    drafts: "Drafts",
    invites: "Invites",
    join_requests: "Join requests",
    waiting_list: "Waiting list",
    public_plural: "Public",
    private_plural: "Private",
    administrating: "Administrating",
    my_teams: "My teams",
    team_owner: "Owner",
    my_clubs: "My organizations",
    manager: "Manager",
    my_venues: "My venues",
    profile_uppercase: "Profile",
    rankings: "Rankings",
    stats: "Stats",
    dates: "Dates",
    to: "to",
    category: "Category",
    status: "Status",
    open_registration: "Open Registration",
    in_progress: "In Progress",
    ended: "Ended",
    send_team_invites_to_multiple_players:
      "Send team invites to multiple players",
    wins_caps: "WINS",
    losses_caps: "LOSSES",
    ties_caps: "TIES",
    add_new_announcement_caps: "ADD NEW ANNOUNCEMENT",
    cancel: "cancel",
    announcements: "Announcements",
    announcement: "Announcement",
    select_severity: "Select severity",
    information: "Information",
    warning: "Warning",
    cancelation: "Cancelation",
    clubs_overview: "Organizations overview",
    venues_overview: "Venues overview",
    sign_in_with: "Sign in with",
    log_in: "Log in",
    log_in_with: "Log in with",
    log_out: "Log out",
    draft: "Draft",
    reviewed: "Reviewed",
    groups_in_progress: "Groups in progress",
    knockout_in_progress: "Knockout in progress",
    join_requested: "Join requested",
    checked_in_status: "Checked in",
    homeButton: "Back to landing page",
    backButton: "Back",
    new_password: "New Password",
    email: "Email",
    emailRule1: "Email is required",
    emailRule2: "Incorrect email format",
    passwordRule1: "Password is required",
    passwordRule2: "Password is weak",
    confirmAccount_title: "Confirm Account",
    confirmAccount_loadingMessage: "Validating link, please wait",
    confirmAccount_successMessage:
      "Your account has been successfully confirmed. You will be redirected to the login page shortly.",
    confirmAccount_expiredLinkMessage:
      "This confirmation link is has expired and is no longer valid.",
    confirmAccount_linkNotFoundMessage: "This confirmation link is not valid.",
    confirmAccount_errorOccurredMessage:
      "An unexpected error has occurred while trying to validate the link. Please try again later.",
    forgotPassword_title: "Reset Password",
    forgotPassword_sendPasswordConfirm: "Reset Password",
    forgotPassword_resetPasswordHint:
      "In order to receive the password reset link, please input the email you used to create your account",
    forgotPassword_sendButton: "Send",
    forgotPassword_successMessage:
      "In the following minutes, an email with the request link will be sent to your address. You will be redirected to the landing page shortly.",
    forgotPassword_notFoundMessage:
      "There is no user registered with that email.",
    forgotPassword_errorOccurredMessage:
      "An unexpected error has occured while trying to validate the email. Please try again later.",
    resendConfirmation_title: "Resend Account Confirmation",
    resendConfirmation_sendPasswordConfirm: "Resend Account Confirmation",
    resendConfirmation_resetPasswordHint:
      "In order to receive the account confirmation link again, please input the email you used to create your account",
    resendConfirmation_sendButton: "Send",
    resendConfirmation_successMessage:
      "In the following minutes, an email with the request link will be sent to your address. You will be redirected to the landing page shortly.",
    resendConfirmation_notFoundMessage:
      "There is no user registered with that email.",
    resendConfirmation_errorOccurredMessage:
      "An unexpected error has occured while trying to validate the email. Please try again later.",
    resetPassword_title: "Reset Password",
    resetPassword_loadingMessage: "Validating, please wait",
    resetPassword_successMessage:
      "Your verification link has been successfully validated. You may now select a new password.",
    resetPassword_expiredLinkMessage:
      "This confirmation link is has expired and is no longer valid.",
    resetPassword_linkNotFoundMessage: "This confirmation link is not valid.",
    resetPassword_errorOccurredMessage:
      "An unexpected error has occurred while trying to validate the link. Please try again later.",
    resetPasswordSuccess_title: "Password successfully reset.",
    resetPasswordSuccess_text:
      "You can now login with the new password. You will be redirected shortly",
    sign_up: "Sign up",
    registerIntro: "Please complete to create your account",
    firstName: "First name",
    lastName: "Last name",
    password: "Password",
    confirmPassword: "Confirm password",
    agreement: "I agree with Sporteeva's ",
    termAndCond: "privacy policy",
    firstNameRule: "First name is required",
    lastNameRule: "Last name is required",
    nameRule: "Name is required",
    confirmPasswordRule: "Passwords don't match",
    agreementRule: "You must agree to continue!",
    genderRule: "Gender is required",
    strong: "Strong",
    medium: "Medium",
    welcomeTo: "Welcome to",
    registerSuccessful_title: "Registration successful!",
    registerSuccessful_text:
      "Your account has been successfully created! In order to be able to login, you need to confirm your account. You will soon be sent a confirmation link via email.",
    confirmAccountTitle: "Confirm Account",
    confirmAccountSuccess:
      "Your account has been successfully confirmed. You will be redirected to the login page shortly.",
    confirmAccountExpiredLink:
      "This confirmation link is has expired and is no longer valid.",
    confirmAccountNotFoundLink: "This confirmation link is not valid.",
    create_bracket: "Create bracket",
    edit_bracket: "Edit bracket",
    bracket_details: "Bracket details",
    save_changes: "Save changes",
    participant_type: "Participant type",
    bracket_type: "Bracket type",
    expected_size: "Expected size",
    number_of_groups: "Number of groups",
    group_size: "Group size",
    qualified_per_group: "Qualified per group",
    estimated_game_duration: "Estimated Game Duration (minutes)",
    unplanned_games: "Unplanned games",
    unplanned_games_info:
      "Click on the desired cell to populate date, time and field.",
    date: "Date",
    time: "Time",
    field: "Field",
    select_field: "Select field",
    tournament_venue_has_no_fields_configured:
      "Tournament venue has no fields configured for this sport",
    club_details: "Organization details",
    club_address: "Organization address",
    club_owner: "Owner",
    administrator: "Administrator",
    active: "Active",
    promote_to_administrator: "Promote to administrator",
    kick_from_club: "Kick from organization",
    revoke_administration: "Revoke administration",
    bronze_club_package_sub_tip1:
      "Bronze is the first sport level for a organization",
    bronze_club_package_sub_tip2:
      "You can organize two Bronze tournament per month",
    bronze_club_package_sub_tip3:
      "You also have two private tournaments in the subscription",
    bronze_club_package_tip:
      "This level is suitable for Organizations starting on the platform",
    silver_club_package_sub_tip1:
      "At Silver level you have already a few tournaments under your belt and you want more",
    silver_club_package_sub_tip2:
      "At this level you have two Silver tournaments per month included and an unlimited number of Bronze tournaments",
    silver_club_package_sub_tip3:
      "You have six private tournaments in the subscription",
    silver_club_package_tip:
      "This level is suitable for Organizations with some experience and a desire to grow",
    gold_club_package_sub_tip1:
      "Gold level shows you are an experienced tournament organizer",
    gold_club_package_sub_tip2:
      "At this level you have included two Gold tournaments per month and an unlimited number of Silver and Bronze tournaments. You also have a dozen private tournaments included",
    gold_club_package_sub_tip3:
      "Gold means you are very good and trusted by the players",
    gold_club_package_tip:
      "It is a level suitable for the professional tournament organizer, who offers an excellent experience to players",
    platinium_club_package_sub_tip1:
      "You are Platinum, the apex tournament organizer",
    platinium_club_package_sub_tip2:
      "Players expect from you the best services, and you provide them",
    platinium_club_package_sub_tip3: "At this level there are no restrictions",
    platinium_club_package_tip:
      "It is suitable for Organizations with all the required facilities to organize top competitions",
    added_caps: "ADDED",
    expires_caps: "EXPIRES",
    creator: "Creator",
    field_downcase: "field",
    fields_downcase: "fields",
    tournaments_you_are_administrating: "Tournaments you're administrating",
    my_club_invites: "My organization invites",
    clubs_you_manage: "Organizations you manage",
    clubs_you_are_member_of: "Organizations you're a member of",
    tournaments_you_requested_to_join: "Tournaments you've requested to join",
    your_private_active_tournaments: "Your private active tournaments",
    your_public_active_tournaments: "Your public active tournaments",
    my_team_invites: "My team invites",
    teams_you_are_member_of: "Teams you're a member of",
    my_owned_teams: "My Owned teams",
    your_tournament_drafts: "You tournament drafts",
    your_tournament_invites: "Your tournament invites",
    tournaments_you_are_on_waiting_list: "Tournaments you're on waiting list",
    login: "Log in",
    loginIntro: "Please login using your credentials",
    loginDataInvalid: "Email or Password is incorrect",
    accountNotConfirmed: "You must first verify your email address",
    userNotFound: "There is no user for this email",
    resendConfirmationEmail: "Resend confirmation email?",
    dont_have_an_account: "Don't have an account?",
    forgot_password: "Forgot password?",
    remember_me: "Keep me logged in",
    single: "Single",
    doubles: "Doubles",
    single_caps: "SINGLE",
    doubles_caps: "DOUBLES",
    players_caps: "PLAYERS",
    ranked_players: "ranked players",
    free_player_package_sub_tip1: "You can choose 3 sports",
    free_player_package_sub_tip2: "You can create 1 team",
    free_player_package_sub_tip3:
      "You can participate in 1 public tournament per month",
    free_player_package_sub_tip4:
      "You can create 1 private tournament with your friends per month",
    free_player_package_tip: "Compete, play and test our platform",
    weekend_warrior_player_package_sub_tip1: "You can choose 3 sports",
    weekend_warrior_player_package_sub_tip2:
      "You can play in 4 tournaments per month",
    weekend_warrior_player_package_sub_tip3:
      "You can create 1 Bronze public tournament per month",
    weekend_warrior_player_package_sub_tip4:
      "You can create 2 private tournaments with your friends per month",
    weekend_warrior_player_package_tip:
      "You're a consistent player and ocassional organizer",
    semi_pro_player_package_sub_tip1: "You can choose 5 sports",
    semi_pro_player_package_sub_tip2: "You can play in 8 tournaments per month",
    semi_pro_player_package_sub_tip3:
      "You can create one Silver and two Bronze public tournaments per month",
    semi_pro_player_package_sub_tip4:
      "You can create 4 private tournaments with your friends per month",
    semi_pro_player_package_tip:
      "Passionate player, tournament creator, ultimate competitor",
    select_your_level: "Select your level",
    select_your_level_tip:
      "Make an accurate selection for you level, otherwise you could make your opponents feel uncomfortable",
    already_hanve_an_account: "Already have an account?",
    if_you_have_any_questions_send_email:
      "If you have any questions send us an email at",
    date_of_birth: "Date of birth",
    show_personal_data_on_my_profile: "Show personal data on my profile",
    edit_team: "Edit team",
    team_details: "Team details",
    no_team_sports_configured_for_profile:
      "No team sports unlocked for you profile",
    teams_caps: "TEAMS",
    ranked_teams: "ranked teams",
    as: "as",
    edit_the_tournament: "Edit tournament",
    tournament_details: "Tournament details",
    registration_by_confirmation_only: "Registration by confirmation only",
    number_of_fields: "Number of fields",
    start_date: "Start date",
    end_date: "End date",
    no_club_sports: "No sports unlocked for your organization",
    no_player_sports: "No sports unlocked for you profile",
    stage: "Stage",
    match_date: "Match date",
    match_time: "Match time",
    save_uppercase: "Save",
    cancel_uppercase: "Cancel",
    schedule: "Schedule",
    sets: "Sets",
    venue_details: "Venue details",
    venue_address: "Venue address",
    field_details: "Field details",
    this_website_uses_cookies:
      "This website uses cookies to ensure you get the best experience on our website.",
    our_privacy_policy: "Our privacy policy",
    you_profile_on_sporteeva: "Your Profile on sporteeva.com",
    settings: "Settings",
    language: "Language",
    romanian: "Romanian",
    english: "English",
    german: "German",
    update_profile_settings: "Update profile settings",
    contains: "contains",
    match: "Match",
    language_change: "This change will be reflected at your next login",
    terms_and_conditions: "Terms and conditions",
    social_account_not_registered: "This account is not registered",
    no_sport_warning:
      "To register for this bracket you need to unlock this sport. Do you want to do it now ?",
    yes: "Yes",
    no: "No",
    configurating: "Configurating",
    end_configuration: "End configuration",
    photo_gallery: "Photo Gallery",
    photo_information: "Photo Information",
    gallery_information: "Gallery Information",
    select_players_to_tag: "Tag with players",
    unselect_all: "Unselect all",
    select_all: "Select all",
    upload: "Upload",
    tournament: "Tournament",
    no_phone_warning: "The tournament owner has set the phone number as mandatory for registration. Would you like to verify your number now?",
    registration_phone_required: "Registration requires you phone number",
    update_phone_number: "Update your phone number",
    update_phone_text: "Some tournaments require your phone number, to keep you informed by SMS about last-minute changes and announcements. Please validate your phone number. It will be used ONLY for this.",
    send_validation_code: "Send validation code",
    confirm_phone_number: "Confirm phone number",
    fill_in_code: "Please input the code received at",
    did_not_received_code: "I didn't receive the SMS code",
    message: "Message",
    validation_code_invalid: "The validation code is invalid",
    validation_code_incomplete: "The validation code is incomplete",
    phone_number_invalid: "The phone number is invalid",
    country_code: "Country code",
    choose_a_country: "Choose a country",
    phone_number: "Phone number",
    example: "Example :",
    anti_covid_recommendations: "Anti-COVID19 recommendations",
    anti_covid_no_spectators: "The presence of spectators is not recommended",
    anti_covid_field_change: "It is recommended to change the court only at set",
    anti_covid_no_locker_rooms: "The use of bathrooms and locker rooms should be avoided",
    anti_covid_presence: "The attendance at matches will be made at your own risk and with the player assuming responsability for all the implications arising from his presence there",
    anti_covid_handshake: "After the matches, any form of socialization will be avoided, including the traditional handshake",
    anti_covid_handwash: "Proper personal hygiene will be maintained",
    anti_covid_distancing: "Keep the recommendations for social distancing",
    anti_covid_no_cash: "Participation fees will be paid as much as possible, using online means",
    anti_covid_bench: "Players will spend breaks between games on opposite sides of the court",
    trophies: "Trophies",
    your_team: "Your team",
    hide_level: "Hide Level",
    show_level: "Show Level",
    hide_stats: "Hide Stats",
    show_stats: "Show Stats",
    tournament_winner: "Tournament Winner",
    last_round: "Last Round",
    points_gained: "Points Gained",
    see_matches: "See matches",
    hide_matches: "Hide matches",
    next_tournaments: "Next tournaments",
    managed_tournaments: "Managed tournaments",
    next_matches: "Next matches",
    past_competitions: "Past competitions",
    average: "Average",
    select_bracket_team_players: "Select the members who will participate",
    select_a_team_to_register: "Select a team to register",
    select_min_max_team_members: "Select at least {min} members and maximum {max} to register",
    no_photos_in_galery: "Currently there are no photos in the gallery."
  },
  de: {
    points: "Punkte",
    configuring_groups: "Gruppen konfigurieren",
    confirm: "bestätigen",
    group: "Gruppe",
    participants: "Teilnehmer",
    drag: "ziehen",
    the_players: "spieler",
    the_doubles: "doppel",
    the_teams: "mannschaften",
    change_groups_zone: "in dieser Zone, um die Gruppen zu ändern",
    wins: "Gewinnt",
    wins_abbreviation: "W",
    drawns: "Gezeichnet",
    drawns_abbreviation: "D",
    losses: "Verluste",
    losses_abbreviation: "L",
    goals_for: "Tore für",
    goals_for_abbreviation: "GF",
    goals_against: "Tore gegen",
    goals_against_abbreviation: "GA",
    goals_difference: "Tordifferenz",
    goals_difference_abbreviation: "GD",
    points_for: "Punkte für",
    points_for_abbreviation: "PF",
    points_against: "Punkte dagegen",
    points_against_abbreviation: "PA",
    points_difference: "Punktedifferenz",
    points_difference_abbreviation: "PD",
    sets_for: "Sets für",
    sets_for_abbreviation: "SF",
    sets_against: "Sets gegen",
    sets_against_abbreviation: "SA",
    sets_difference: "Setsdifferenz",
    sets_difference_abbreviation: "SD",
    games_for: "Spiele für",
    games_for_abbreviation: "SF",
    games_against: "Spiele gegen",
    games_against_abbreviation: "SA",
    games_difference: "Spieledifferenz",
    games_difference_abbreviation: "SD",
    standing: "Stehen/Stellung",
    matches: "Spiele",
    points_downcase: "Punkte",
    invite: "Einladung",
    send_invites_multiple_players: "Sende Einladungen an mehrere Spieler",
    select_players_to_invite:
      "Wählen Sie die Spieler aus, die Sie einladen möchten",
    send_invites_multiple_teams: "Senden Sie Einladungen an mehrere Teams",
    search_team: "Team suchen",
    configuring_knockout: "Knock-out konfigurieren",
    drag_area: "Bereich ziehen",
    first_round: "Erste Runde",
    long_press: "(Lange drücken)",
    change_matches_zone: "in dieser Zone, um die Spiele zu ändern",
    current_round: "Aktuelle Runde",
    quarter_final: "Viertelfinalspiel",
    semi_final: "Halbfinale",
    final: "Finale",
    winner: "Gewinner",
    register: "Registrieren",
    no_teams_or_gender:
      "Sie haben keine Teams auf dieser Niveau oder das Geschlecht stimmt nicht überein",
    no_level_configured_for_sport:
      "Für diesen Sport ist kein Level konfiguriert",
    gender_doesnt_match_bracket:
      "Ihr Geschlecht stimmt nicht mit dem Geschlecht der Gruppe überein",
    level_too_low: "Dein Level ist zu niedrig",
    level_too_high: "Dein Level ist zu hoch",
    waiting: "Warten",
    invited: "Eingeladet",
    registered: "Eingetragen",
    reject: "Ablehnen",
    accept: "Akzeptieren",
    accept_invite_and_participate_with:
      "Akzeptiere Einladung und Teil mitnehmen",
    waiting_for_confirmation: "Warten auf Bestätigung von",
    administrator_invite:
      "Sie wurden eingeladen, an dieser Klammer teilzunehmen",
    you_were_invited_by: "Sie wurden von eingeladen",
    multiple_bracket_invites:
      "Sie wurden eingeladen, an dieser Halterung teilzunehmen. Bitte wählen Sie aus, wie Sie teilnehmen möchten",
    you_are_invited_to_participate:
      "Sie sind wurden eingeladen, um teilzunehmen",
    you_are_invited_with_your_team:
      "Sie sind eingeladen, mit Ihrem Team teilzunehmen",
    to_register_for_double_bracket:
      "Um sich für eine Doppelhalterung zu registrieren, müssen Sie sich mit einem Spieler paaren",
    of_same_level_or_below: "der gleichen Niveau oder ein Niveau darunter",
    select_your_partner: "Wählen Sie Ihren Partner",
    partner: "Partner",
    retire: "In den Ruhestand gehen",
    you_are_registered: "Du bist registriert",
    with_downcase: "mit",
    you_are_on_waiting_list:
      "Du bist auf der Warteliste. Wenn genügend angemeldete Teilnehmer in den Ruhestand treten oder die Größe der Klammern erweitert wird, werden Sie automatisch angemeldet",
    to_regiser_for_team_bracket:
      "Um sich für eine Team-Gruppe anzumelden, müssen Sie eines Ihrer Teams auswählen",
    your_teams: "Ihre Teams",
    no_teams_for_this_bracket:
      "Sie besitzen keine Teams für diese Halterung-Konfiguration",
    requested: "Angefordert",
    you_requested_to_join: "Sie haben um Teilnahme gebeten",
    revoke: "Widerrufen",
    format: "Struktur",
    registration_fee: "Anmeldegebühr",
    free: "KOSTENLOS",
    per_person: "/Person",
    mark_as_reviewed: "Als überprüft markieren",
    edit: "Bearbeiten",
    delete: "Löschen",
    male: "Mann",
    female: "frau",
    undefined: "Nicht definiert",
    level_caps: "NIVEAU",
    rank_caps: "RANG",
    kick_from_bracket: "Tritt aus der Halterung",
    cancel_invitation: "Einladung stornieren",
    accept_request: "Anfrage annehmen",
    decline_request: "Anfrage ablehnen",
    checked_in_action: "Eingecheckt",
    not_defined: "Nicht definiert",
    energy_caps: "ENERGIE",
    from: "von",
    per_month: "/Monat",
    select: "Wählen",
    send_club_invites_to_multiple: "Organisationseinladungen an mehrere Spieler senden",
    today: "Heute",
    months: [
      "Januar",
      "Februar",
      "März",
      "April",
      "Ma",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember"
    ],
    weekdays: [
      "Sonntag",
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag"
    ],
    latest_results: "Neueste Ergebnisse",
    jan: "Jan",
    feb: "Feb",
    mar: "März",
    apr: "Apr",
    may: "Mai",
    jun: "Jun",
    jul: "Jul",
    aug: "Aug",
    sep: "Sep",
    whats_next: "Was kommt als nächstes??",
    create_tournament: "Turnier erstellen",
    create_team: "Team erstellen",
    create_venue: "Tagungsort erstellen",
    create_club: "Organisation erstellen",
    tournament_matches_next_7days:
      "Turniere die in den nächsten 7 Tagen stattfinden",
    member: "Mittglied",
    members: "Mittglieder",
    sports_caps: "SPORT",
    sport_caps: "SPORT",
    more: "mehr",
    owner: "Besitzer",
    requested_downcase: "angefordert",
    location: "Ort",
    location_address: "Standort-Adresse",
    tournament_dates: "Turnierdaten",
    public: "öffentlich",
    private: "Privat",
    upcoming_tournaments:
      "Kommende Turniere in Ihrer Stadt basierend auf Ihrem Profilsport",
    no_tournaments_your_area:
      "Im Moment gibt es keine Turniere in Ihrer Nähe. Bitte überprüfen Sie auch, ob 'Stadt' konfiguriert ist",
    profile: "Profil",
    start_using_sporteeva: "Starten Sie mit Sporteeva",
    first_thing: "Das wichtigste zuerst!",
    first_need_to_unlock_a_sport:
      "Um Sporteeva nutzen zu können, müssen Sie zuerst eine Sportart freischalten. Machen Sie sich keine Sorgen, dass Ihre erste Sportart bei uns ansteht, sodass Sie für die Nutzung von Sporteeva nichts bezahlen müssen. Geh voraus, such dir einen Sport aus!",
    here_are_some_tips: "Hier sind einige Tipps für den Einstieg",
    global_search_tool_tip:
      "In Sporteeva gibt es viele Möglichkeiten, Dinge zu finden. Sie können das globale Suchwerkzeug am oberen Rand der Benutzeroberfläche verwenden, um so gut wie alles in Sporteeva zu finden.",
    main_navigation_tip:
      "Wenn Sie etwas Bestimmtes suchen, können Sie die Links in der Hauptnavigation verwenden oder auf eine der folgenden Optionen klicken:",
    start_a_tournament: "Starte ein Turnier",
    create_a_club: "Erstelle einen Verein",
    create_a_team: "Bilden Sie ein Team",
    start_a_match: "Starte ein Match",
    register_a_venue: "Registrieren Sie einen Veranstaltungsort",
    find_a_specific_player: "Finde einen bestimmten Spieler",
    welcome_to_your_dashboard: "Willkommen in Ihrem Dashboard",
    hi: "Hallo",
    as_you_get_involved:
      "Wenn Sie sich an Veranstaltungen und Organisationen in Sporteeva beteiligen, füllt sich Ihr Dashboard mit neuen Inhalten",
    example_you_might_see_here:
      "Einige Beispiele für das, was Sie hier möglicherweise sehen, sind::",
    your_upcoming_match_schedule: "Dein bevorstehender Spielplan",
    upcoming_events:
      "Kommende Veranstaltungen, an denen Sie interessiert sein könnten",
    recent_results: "Aktuelle Ergebnisse",
    end_group_stage: "Gruppenphase beenden",
    since: "Seit",
    add_sport: "Sport hinzufügen",
    join: "Teilnehmen",
    member_uppercase: "Mitglied",
    you_are_a_member_of_this_club: "Du bist ein Mitglied dieser Organisation",
    leave: "Verlassen",
    you_are_invited_to_this_club:
      "Sie sind eingeladen, diesem Klub beizutreten",
    you_have_requested_to_join_this_club:
      "Sie haben gebeten, diese Organisation beitreten",
    sport: "Sport",
    level: "Niveau",
    you_are_a_member_of_this_team: "Du bist ein Mitglied dieses Teams",
    you_are_invited_to_this_team:
      "Sie sind eingeladen, sich diesem Team anzuschließen",
    you_have_requested_to_join_this_team:
      "Sie haben darum gebeten, diesem Team beizutreten",
    public_tournament_downcase: "öffentliches Turnier",
    private_tournament_downcase: "Privates Turnier",
    no_of_courts: "Anzahl der Sportplätze",
    registration_ends: "Die Registrierung endet",
    end_tournament: "Turnier beenden",
    end_registration: "Registrierung beenden",
    publish: "Veröffentlichen",
    add_bracket: "Halterung hinzufügen",
    create_field: "Feld erstellen",
    edit_field: "Feld bearbeiten",
    tournaments: "Turniere",
    clubs: "Organisationen",
    venues: "Veranstaltungsorte",
    venue: "Tagungsort",
    search_venue: "Tagungsort suchen",
    teams: "Mannschaften",
    players: "Spieler",
    type_in_to_search: "Geben Sie ein, um zu suchen...",
    add: "Hinzufügen",
    single_rank_caps: "EINZELRANG",
    double_rank_caps: "DOPPELRANG",
    tournaments_downcase: "Turniere",
    team_level_caps: "Mannschaft Niveau",
    team_rank_caps: "Mannschaft Rang",
    round: "Runde",
    score: "Ergebnis",
    opponents: "Gegner",
    opponent: "Gegner",
    registration_date: "Registrierungsdatum",
    double_tournaments: "Doppelte Turniere",
    double_matches: "Doppelte Spiele",
    single_tournaments: "Einzelturniere",
    single_matches: "Einzelspiele",
    tournaments_this_year: "Turniere in diesem Jahr",
    titles_per_finals: "Titel / Endrunde",
    platinium_champion: "Platinium-Champion",
    platinium_finalist: "Platin-Finalist",
    gold_champion: "Gold-Champion",
    gold_finalist: "Gold-Finalist",
    silver_champion: "Silver-Champion",
    silver_finalist: "Silver-Finalist",
    bronze_champion: "Bronze-Champion",
    bronze_finalist: "Bronze-Finalist",
    rank: "Rang",
    player: "Spieler",
    team: "Mannschaft",
    club: "Organisation",
    rank_move: "Bewegen",
    selected: "Ausgwählen",
    fields: "Felder",
    group_stage: "Gruppenbühne",
    knockout_stage: "Ko-Phase",
    calendar: "Kalender",
    gender: "Geschlecht",
    type: "Art",
    brackets: "Halterung",
    members_uppercase: "Mitglieder",
    sports: "Sports",
    search_results: "Suchergebnisse",
    name: "Name",
    country: "Land",
    county: "Bezirk",
    city: "Stadt",
    dashboard: "Instrumententafel",
    my_active_tournaments: "Meine aktiven Turniere",
    drafts: "Entwürfe",
    invites: "Einladungen",
    join_requests: "Join-Anfragen",
    waiting_list: "Warteliste",
    public_plural: "Öffentlichkeit",
    private_plural: "Privat",
    administrating: "Verwalten",
    my_teams: "Meine Mannschaften",
    team_owner: "Inhaber",
    my_clubs: "Meine Organisationen",
    manager: "Manager",
    my_venues: "Meine Veranstaltungsorte",
    profile_uppercase: "Profil",
    rankings: "Rangliste",
    stats: "Statistiken",
    dates: "Daten",
    to: "zu",
    category: "Kategorie",
    status: "Status",
    open_registration: "Registrierung öffnen",
    in_progress: "In Bearbeitung",
    ended: "Beendet",
    send_team_invites_to_multiple_players:
      "Teameinladungen an mehrere Spieler senden",
    wins_caps: "GEWINNT",
    losses_caps: "VERLUSTE",
    ties_caps: "UNENTSCHIEDEN",
    add_new_announcement_caps: "NEUE MITTEILUNG HINZUFÜGEN",
    cancel: "stornieren",
    announcements: "Ankündigungen",
    announcement: "Ankündigung",
    select_severity: "Wählen Sie den Schweregrad",
    information: "Information",
    warning: "Warnung",
    cancelation: "Stornierung",
    clubs_overview: "Vereinsübersicht",
    venues_overview: "Veranstaltungsortübersicht",
    sign_in_with: "Melden Sie sich mit an",
    log_in: "Einloggen",
    log_in_with: "Einloggen mit",
    log_out: "Ausloggen",
    draft: "Entwurf",
    reviewed: "Überprüft",
    groups_in_progress: "Gruppen in Bearbeitung",
    knockout_in_progress: "Ausscheidungswettkampf läuft",
    join_requested: "Beitritt erbeten",
    checked_in_status: "Eingecheckt",
    homeButton: "Zurück zur Zielseite",
    backButton: "Zurück",
    new_password: "Neues Kennwort",
    email: "Email",
    emailRule1: "Email wird benötigt",
    emailRule2: "Falsches E-Mail-Format",
    passwordRule1: "Passwort wird benötigt",
    passwordRule2: "Passwort ist schwach",
    confirmAccount_title: "Konto bestätigen",
    confirmAccount_loadingMessage: "Link wird überprüft, bitte warten",
    confirmAccount_successMessage:
      "Ihr Konto wurde erfolgreich bestätigt. Sie werden in Kürze zur Anmeldeseite weitergeleitet.",
    confirmAccount_expiredLinkMessage:
      "Dieser Bestätigungslink ist abgelaufen und nicht mehr gültig.",
    confirmAccount_linkNotFoundMessage: "Dieser Bestätigungslink ist ungültig.",
    confirmAccount_errorOccurredMessage:
      "Beim Überprüfen des Links ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
    forgotPassword_title: "Passwort zurücksetzen",
    forgotPassword_sendPasswordConfirm: "Passwort zurücksetzen",
    forgotPassword_resetPasswordHint:
      "Um den Link zum Zurücksetzen des Passworts zu erhalten, geben Sie bitte die E-Mail-Adresse ein, die Sie zum Erstellen Ihres Kontos verwendet haben",
    forgotPassword_sendButton: "Senden",
    forgotPassword_successMessage:
      "In den folgenden Minuten wird eine E-Mail mit dem Anforderungslink an Ihre Adresse gesendet. Sie werden in Kürze zur Zielseite weitergeleitet.",
    forgotPassword_notFoundMessage:
      "Es ist kein Benutzer mit dieser E-Mail registriert.",
    forgotPassword_errorOccurredMessage:
      "Beim Überprüfen der E-Mail ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
    resendConfirmation_title: "Senden Sie die Kontobestätigung erneut",
    resendConfirmation_sendPasswordConfirm:
      "Senden Sie die Kontobestätigung erneut",
    resendConfirmation_resetPasswordHint:
      "Geben Sie die E-Mail-Adresse ein, mit der Sie Ihr Konto erstellt haben, um den Link zur Bestätigung des Kontos erneut zu erhalten.",
    resendConfirmation_sendButton: "Senden",
    resendConfirmation_successMessage:
      "In den folgenden Minuten wird eine E-Mail mit dem Anforderungslink an Ihre Adresse gesendet. Sie werden in Kürze zur Zielseite weitergeleitet.",
    resendConfirmation_notFoundMessage:
      "Es ist kein Benutzer mit dieser E-Mail registriert.",
    resendConfirmation_errorOccurredMessage:
      "Beim Überprüfen der E-Mail ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
    resetPassword_title: "Passwort zurücksetzen",
    resetPassword_loadingMessage: "Validierung, bitte warten",
    resetPassword_successMessage:
      "Ihr Bestätigungslink wurde erfolgreich validiert. Sie können jetzt ein neues Passwort auswählen.",
    resetPassword_expiredLinkMessage:
      "Dieser Bestätigungslink ist abgelaufen und nicht mehr gültig.",
    resetPassword_linkNotFoundMessage: "Dieser Bestätigungslink ist ungültig.",
    resetPassword_errorOccurredMessage:
      "Beim Überprüfen des Links ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
    resetPasswordSuccess_title: "Passwort erfolgreich zurückgesetzt.",
    resetPasswordSuccess_text:
      "Sie können sich jetzt mit dem neuen Passwort anmelden. Sie werden in Kürze weitergeleitet",
    sign_up: "Anmelden",
    registerIntro: "Bitte vervollständigen Sie, um Ihr Konto zu erstellen",
    firstName: "Vorname",
    lastName: "Nachname",
    password: "Passwort",
    confirmPassword: "Kennwort bestätigen",
    agreement: "Ich stimme Sporteeva's zu ",
    termAndCond: "Datenschutz-Bestimmungen",
    firstNameRule: "Vorname ist erforderlich",
    lastNameRule: "Nachname ist erforderlich",
    nameRule: "Name ist erforderlich",
    confirmPasswordRule: "Passwörter stimmen nicht überein",
    agreementRule: "Sie müssen zustimmen, um fortzufahren!",
    genderRule: "Gender is required",
    strong: "Stark",
    medium: "Mittel",
    welcomeTo: "Willkommen zu",
    registerSuccessful_title: "Registrierung erfolgreich!",
    registerSuccessful_text:
      "Ihr Account wurde erfolgreich erstellt! Um sich einloggen zu können, müssen Sie Ihr Konto bestätigen. Sie erhalten in Kürze einen Bestätigungslink per E-Mail.",
    confirmAccountTitle: "Konto bestätigen",
    confirmAccountSuccess:
      "Ihr Konto wurde erfolgreich bestätigt. Sie werden in Kürze zur Anmeldeseite weitergeleitet.",
    confirmAccountExpiredLink:
      "Dieser Bestätigungslink ist abgelaufen und nicht mehr gültig.",
    confirmAccountNotFoundLink: "Dieser Bestätigungslink ist ungültig.",
    create_bracket: "Halterung erstellen",
    edit_bracket: "Halterung bearbeiten",
    bracket_details: "Halterung Details",
    save_changes: "Änderungen speichern",
    participant_type: "Teilnehmertyp",
    bracket_type: "Halterungstyp",
    expected_size: "Erwartete Größe",
    number_of_groups: "Anzahl der Gruppen",
    group_size: "Gruppengröße",
    qualified_per_group: "Qualifiziert pro Gruppe",
    estimated_game_duration: "Geschätzte Spieldauer (Minuten)",
    unplanned_games: "Ungeplante Spiele",
    unplanned_games_info:
      "Klicken Sie auf die gewünschte Zelle, um Datum, Uhrzeit und Feld auszufüllen.",
    date: "Datum",
    time: "Zielseite",
    field: "Feld",
    select_field: "Feld auswählen",
    tournament_venue_has_no_fields_configured:
      "Der Turnierort hat keine für diesen Sport konfigurierten Felder",
    club_details: "Organisationdetails",
    club_address: "Organisationadresse",
    club_owner: "Inhaber",
    administrator: "Administrator",
    active: "Aktiv",
    promote_to_administrator: "Zum Administrator befördern",
    kick_from_club: "Tritt vom Verein",
    revoke_administration: "Administration widerrufen",
    bronze_club_package_sub_tip1:
      "Bronze ist das erste Sportlevel für einen Verein",
    bronze_club_package_sub_tip2:
      "Sie können zwei Bronzeturniere pro Monat organisieren",
    bronze_club_package_sub_tip3:
      "Sie haben auch zwei private Turniere im Abonnement",
    bronze_club_package_tip:
      "Dieses Level ist für Organisationen geeignet, die auf der Plattform starten",
    silver_club_package_sub_tip1:
      "Bei Silber hast du bereits ein paar Turniere auf dem Buckel und willst mehr",
    silver_club_package_sub_tip2:
      "Auf diesem Level gibt es zwei Silver-Turniere pro Monat und eine unbegrenzte Anzahl von Bronze-Turnieren",
    silver_club_package_sub_tip3:
      "Sie haben sechs private Turniere im Abonnement",
    silver_club_package_tip:
      "Dieses Level eignet sich für Organisationen mit etwas Erfahrung und dem Wunsch zu wachsen",
    gold_club_package_sub_tip1:
      "Gold Level zeigt, dass Sie ein erfahrener Turnierorganisator sind",
    gold_club_package_sub_tip2:
      "Auf dieser Niveau haben Sie zwei Gold-Turniere pro Monat und eine unbegrenzte Anzahl von Silber- und Bronzeturnieren eingeschlossen. Sie haben auch ein Dutzend private Turniere enthalten",
    gold_club_package_sub_tip3:
      "Gold bedeutet, dass Sie von den Spielern sehr gut und vertrauenswürdig sind",
    gold_club_package_tip:
      "Es ist ein Level, das für den professionellen Turnierveranstalter geeignet ist, der den Spielern eine hervorragende Erfahrung bietet",
    platinium_club_package_sub_tip1:
      "Sie sind Platinum, der Veranstalter des Apex-Turniers",
    platinium_club_package_sub_tip2:
      "Die Spieler erwarten von Ihnen die besten Dienste und Sie bieten sie an",
    platinium_club_package_sub_tip3:
      "Auf dieser Ebene gibt es keine Einschränkungen",
    platinium_club_package_tip:
      "Es eignet sich für sehr Vereine mit allen erforderlichen Einrichtungen, um Top-Wettbewerbe zu organisieren",
    added_caps: "HINZUGEFÜGT",
    expires_caps: "LÄUFT AB",
    creator: "Ersteller",
    field_downcase: "Feld",
    fields_downcase: "Felder",
    tournaments_you_are_administrating: "Turniere, die Sie verwalten",
    my_club_invites: "Mein Verein lädt ein",
    clubs_you_manage: "Organisationen, die Sie verwalten",
    clubs_you_are_member_of: "Organisationen, in denen Sie Mitglied sind",
    tournaments_you_requested_to_join:
      "Turniere, an denen Sie teilnehmen möchten",
    your_private_active_tournaments: "Ihre privaten aktiven Turniere",
    your_public_active_tournaments: "Ihre öffentlichen aktiven Turniere",
    my_team_invites: "Mein Teams Einladungen",
    teams_you_are_member_of: "Teams, in denen Sie Mitglied sind",
    my_owned_teams: "Meine eigenen Teams",
    your_tournament_drafts: "Ihre Turnierentwürfe",
    your_tournament_invites: "Ihre Turniereinladungen",
    tournaments_you_are_on_waiting_list:
      "Turniere, bei denen Sie auf der Warteliste stehen",
    login: "Einloggen",
    loginIntro: "Bitte melden Sie sich mit Ihren Zugangsdaten an",
    loginDataInvalid: "Email oder Passwort ist falsch",
    accountNotConfirmed: "Sie müssen zuerst Ihre E-Mail-Adresse bestätigen",
    userNotFound: "Es gibt keinen Benutzer für diese E-Mail",
    resendConfirmationEmail: "Bestätigungsmail erneut senden?",
    dont_have_an_account: "Sie haben noch keinen Account?",
    forgot_password: "Passwort vergessen?",
    remember_me: "Eingeloggt bleiben",
    single: "Einzel",
    doubles: "Doppel",
    single_caps: "EINZEL",
    doubles_caps: "DOPPEL",
    players_caps: "SPIELER",
    ranked_players: "rangierte Spieler",
    free_player_package_sub_tip1: "Sie können 3 Sportarten wählen",
    free_player_package_sub_tip2: "Sie können 1 Team erstellen",
    free_player_package_sub_tip3:
      "Sie können an 1 öffentlichen Turnier pro Monat teilnehmen",
    free_player_package_sub_tip4:
      "Sie können 1 privates Turnier mit Ihren Freunden pro Monat erstellen",
    free_player_package_tip:
      "Messen Sie sich, spielen Sie und testen Sie unsere Plattform",
    weekend_warrior_player_package_sub_tip1: "Sie können 3 Sportarten wählen",
    weekend_warrior_player_package_sub_tip2:
      "Sie können an 4 Turnieren pro Monat teilnehmen",
    weekend_warrior_player_package_sub_tip3:
      "Sie können 1 öffentliches Bronze-Turnier pro Monat erstellen",
    weekend_warrior_player_package_sub_tip4:
      "Sie können 2 private Turniere mit Ihren Freunden pro Monat erstellen",
    weekend_warrior_player_package_tip:
      "Du bist ein beständiger Spieler und gelegentlicher Organisator",
    semi_pro_player_package_sub_tip1: "Sie können 5 Sportarten wählen",
    semi_pro_player_package_sub_tip2:
      "Sie können an 8 Turnieren pro Monat teilnehmen",
    semi_pro_player_package_sub_tip3:
      "Sie können ein öffentliches Silber- und zwei Bronze-Turnier pro Monat erstellen",
    semi_pro_player_package_sub_tip4:
      "Sie können 4 private Turniere mit Ihren Freunden pro Monat erstellen",
    semi_pro_player_package_tip:
      "Leidenschaftlicher Spieler, Turniergestalter, ultimativer Konkurrent",
    select_your_level: "Wählen Sie Ihr Niveau",
    select_your_level_tip:
      "Treffen Sie eine genaue Auswahl für Ihr Level, da sich Ihre Gegner sonst unwohl fühlen könnten",
    already_hanve_an_account: "Sie haben bereits ein Konto?",
    if_you_have_any_questions_send_email:
      "Wenn Sie Fragen haben, senden Sie uns eine E-Mail an",
    date_of_birth: "Geburtsdatum",
    show_personal_data_on_my_profile:
      "Zeige persönliche Daten in meinem Profil",
    edit_team: "Team bearbeiten",
    team_details: "Mannschaftsdetails",
    no_team_sports_configured_for_profile:
      "Für Ihr Profil sind keine Teamsportarten freigeschaltet",
    teams_caps: "MANNSCHAFTEN",
    ranked_teams: "Ranglisten-Teams",
    as: "wie",
    edit_the_tournament: "Turnier bearbeiten",
    tournament_details: "Turnierdetails",
    registration_by_confirmation_only: "Anmeldung nur mit Bestätigung",
    number_of_fields: "Anzahl der Felder",
    start_date: "Anfangsdatum",
    end_date: "Endtermin",
    no_club_sports: "Keine Sportarten für Ihren Verein freigeschaltet",
    no_player_sports: "Keine Sportarten für Ihr Profil freigeschaltet",
    stage: "Stufe",
    match_date: "Spieldatum",
    match_time: "Spielzeit",
    save_uppercase: "speichern",
    cancel_uppercase: "Stornieren",
    schedule: "Zeitplan",
    sets: "Sets",
    venue_details: "Details zum Veranstaltungsort",
    venue_address: "Adresse des Veranstaltungsortes",
    field_details: "Felddetails",
    this_website_uses_cookies:
      "Diese Website verwendet Cookies, um Ihnen das bestmögliche Erlebnis auf unserer Website zu ermöglichen.",
    our_privacy_policy: "Unsere Datenschutzerklärung",
    you_profile_on_sporteeva: "Ihr Profil auf sporteeva.com",
    settings: "die Einstellungen",
    language: "Sprache",
    romanian: "Rumänisch",
    english: "Englisch",
    german: "Deutsch",
    update_profile_settings: "Profileinstellungen aktualisieren",
    contains: "enthält",
    match: "Spiel",
    language_change: "Diese Änderung wird bei Ihrem nächsten Login berücksichtigt",
    terms_and_conditions: "Geschäftsbedingungen",
    social_account_not_registered: "Dieses Konto ist nicht registriert.",
    no_sport_warning:
      "Um sich für diese Halterung zu registrieren, müssen Sie diesen Sport freischalten. Willst du es jetzt tun?",
    yes: "Ja",
    no: "Nein",
    configurating: "Konfigurieren",
    end_configuration: "Konfigurieren beenden",
    photo_gallery: "Fotogallerie",
    photo_information: "Fotoinformationen",
    gallery_information: "Galerie-Informationen",
    select_players_to_tag: "Tag mit Spielern",
    unselect_all: "Alles wiederufen",
    select_all: "Wählen Sie alle",
    upload: "Hochladen",
    tournament: "Turnier",
    no_phone_warning: "Der Turnierbesitzer hat die Telefonnummer als obligatorisch festgelegt. Möchten Sie Ihre Telefonnummer jetzt verifizieren?",
    registration_phone_required: "Die Registrierung erfordert Ihre Telefonnummer",
    update_phone_number: "Telefonnummer aktualisieren",
    update_phone_text: "Einige Turniere erfordern Ihre Telefonnummer, um Sie per SMS über Last-Minute Ankündigungen auf dem Laufenden zu halten. Bitte überprüfen Sie Ihre Telefonnummer. Es wird NUR dafür verwendet werden.",
    send_validation_code: "Bestätigungscode senden",
    confirm_phone_number: "Telefonnummer bestätigen",
    fill_in_code: "Bitte geben Sie den empfangenen Code ein",
    did_not_received_code: "Ich habe den SMS-Code nicht erhalten",
    message: "Nachricht",
    validation_code_invalid: "Bestätigungscode ist ungültig",
    validation_code_incomplete: "Bestätigungscode ist unvollständig",
    phone_number_invalid: "Telefonnummer ist ungültig",
    country_code: "Landesvorwahl",
    choose_a_country: "Wählen Sie bitte ein Land aus",
    phone_number: "Telefonnummer",
    example: "Beispiel :",
    anti_covid_recommendations: "Anti-COVID19-Empfehlungen",
    anti_covid_no_spectators: "Die Anwesenheit von Zuschauern wird nicht empfohlen",
    anti_covid_field_change: "Es wird empfohlen, das Spielfeld nur am Set zu wechseln",
    anti_covid_no_locker_rooms: "Die Verwendung von Badezimmern und Umkleideräumen sollte vermieden werden",
    anti_covid_presence: "Die Teilnahme an Spielen erfolgt auf eigenes Risiko und der Spieler übernimmt die Verantwortung für alle Auswirkungen, die sich aus seiner Anwesenheit ergeben",
    anti_covid_handshake: "Nach den Spielen wird jede Form der Sozialisation vermieden, einschließlich des traditionellen Händedrucks",
    anti_covid_handwash: "Die richtige persönliche Hygiene wird aufrechterhalten",
    anti_covid_distancing: "Halten Sie die Empfehlungen für soziale Distanzierung",
    anti_covid_no_cash: "Die Teilnahmegebühren werden so weit wie möglich online bezahlt",
    anti_covid_bench: "Die Spieler verbringen Pausen zwischen den Spielen auf gegenüberliegenden Seiten des Feldes",
    trophies: "Trophäen",
    your_team: "deine Mannschaft",
    hide_level: "Niveau ausblenden",
    show_level: "Niveau einblenden",
    hide_stats: "Statistiken ausblenden",
    show_stats: "Statistiken einblenden",
    tournament_winner: "Tur­nier­sie­ger",
    last_round: "Letzte Runde",
    points_gained: "Gewonnene Punkte",
    see_matches: "Spiele anzeigen",
    hide_matches: "Spiele ausblenden",
    next_tournaments: "nächste Turniere",
    managed_tournaments: "verwaltete Turniere",
    next_matches: "nächste Spiele",
    past_competitions: "vergangene Wettbewerbe",
    average: "Durchschnittlich",
    select_bracket_team_players: "Wählen Sie die Mitglieder aus, die teilnehmen möchten",
    select_a_team_to_register: "Wählen Sie ein Team aus, um sich zu registrieren",
    select_min_max_team_members: "Wählen Sie mindestens {min} Mitglieder und maximal {max} aus, um sich zu registrieren",
    no_photos_in_galery: "Derzeit befinden sich keine Fotos in der Galerie."
  }
};

export default {
  get(key) {
    return translations[store.getters["language/selectedLanguage"]][key];
  }
};
