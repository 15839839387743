<template>
  <BasicAccountPage>
    <template slot="top-right">
      {{ $translations.get("dont_have_an_account") }}
      <router-link to="register"
        >{{ $translations.get("sign_up") }}.</router-link
      >
    </template>
    <template slot="title">{{ $translations.get("loginIntro") }}</template>
    <template slot="content">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-layout row wrap class="account-page__input-container">
          <v-flex xs6>
            <v-text-field
              name="email"
              v-model="loginModel.email"
              :label="$translations.get('email')"
              :rules="emailRules"
            ></v-text-field>
          </v-flex>
          <v-flex xs6>
            <v-text-field
              name="password"
              v-model="loginModel.password"
              :label="$translations.get('password')"
              id="password"
              type="password"
              :rules="passwordRules"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-form>
      <v-layout row class="link">
        <v-switch
          class="remember-me"
          v-model="loginModel.rememberMe"
          :label="$translations.get('remember_me')"
        ></v-switch>
        <router-link to="/account/forgotpassword">
          <div class="forgot">{{ $translations.get("forgot_password") }}</div>
        </router-link>
      </v-layout>
    </template>
    <template slot="buttons">
      <v-btn
        :loading="loginLoading"
        dark
        color="success elevation-0"
        large
        @click="login"
        class="account-page__btn"
        >{{ $translations.get("login") }}</v-btn
      >
      <facebook-sign-in
        :auth-callback="loginWithFacebook"
        :loading="facebookPending"
        :label="$translations.get('log_in_with')"
      ></facebook-sign-in>
      <google-sign-in
        :auth-callback="loginWithGoogle"
        :loading="googlePending"
        :label="$translations.get('log_in_with')"
      ></google-sign-in>
    </template>
    <template slot="alerts">
      <div v-if="loginResponseStatus == 'notconfirmed'">
        <v-alert
          :value="true"
          color="error"
          dismissible="true"
          outline="true"
          @click="hideAlerts"
          >{{ $translations.get("accountNotConfirmed") }}</v-alert
        >
        <v-btn
          flat
          small
          color="red"
          @click="$router.push({ name: 'resendconfirmaccount' })"
          >{{ $translations.get("resendConfirmationEmail") }}</v-btn
        >
      </div>
      <v-alert
        v-if="loginResponseStatus == 'invalidcredentials'"
        :value="true"
        color="error"
        dismissible="true"
        outline="true"
        @click="hideAlerts"
        >{{ $translations.get("loginDataInvalid") }}</v-alert
      >
      <v-alert
        v-if="loginResponseStatus == 'accountNotRegistered'"
        :value="true"
        color="error"
        dismissible="true"
        outline="true"
        @click="hideAlerts"
        >{{ $translations.get("social_account_not_registered") }}</v-alert
      >
    </template>
  </BasicAccountPage>
</template>

<script>
import FacebookSignIn from "../../components/FacebookSignIn";
import GoogleSignIn from "../../components/GoogleSignIn";
import eventBusMessages from "../../utils/eventBusMessages";
import BasicAccountPage from "../account/BasicAccountPage";

export default {
  name: "Login",
  components: {
    FacebookSignIn,
    GoogleSignIn,
    BasicAccountPage,
  },
  data() {
    return {
      loginModel: {
        email: "",
        password: "",
        rememberMe: false,
      },
      loginResponseStatus: "",
      valid: true,
      loginLoading: false,
      emailRules: [(email) => !!email || this.$translations.get("emailRule1")],
      passwordRules: [
        (password) => !!password || this.$translations.get("passwordRule1"),
      ],
      facebookPending: false,
      googlePending: false,
    };
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.loginLoading = true;
        this.cleanUpTokens();
        this.$http
          .post("/token/issue", this.loginModel)
          .then((result) => {
            this.loginCallback(result.data);
          })
          .catch((error) => {
            this.$eventBus.$emit(eventBusMessages.toast.CLOSE);
            if (error.response.status == 403) {
              this.loginResponseStatus = "notconfirmed";
            } else {
              this.loginResponseStatus = "invalidcredentials";
            }
            this.loginLoading = false;
          })
          .finally(() => {
            this.loginLoading = false;
          });
      }
    },
    loginWithFacebook() {
      // eslint-disable-next-line
      FB.login(
        function (response) {
          if (response.authResponse) {
            if (response.status === "connected") {
              this.facebookPending = true;
              this.cleanUpTokens();
              this.$http
                .post("/token/facebook/login", {
                  accessToken: response.authResponse.accessToken,
                  rememberMe: this.loginModel.rememberMe,
                })
                .then((result) => {
                  this.loginCallback(result.data);
                })
                .catch(() => {
                  this.$eventBus.$emit(eventBusMessages.toast.CLOSE);
                  this.loginResponseStatus = "accountNotRegistered";
                  this.facebookPending = false;
                })
                .finally(() => {
                  this.facebookPending = false;
                });
            }
          }
        }.bind(this),
        {
          scope: "email,public_profile",
          return_scopes: true,
        }
      );
    },
    loginWithGoogle() {
      this.$gAuth.signIn().then((googleUser) => {
        this.googlePending = true;
        this.cleanUpTokens();
        this.$http
          .post("/token/google/login", {
            accessToken: googleUser.getAuthResponse().access_token,
            rememberMe: this.loginModel.rememberMe,
          })
          .then((result) => {
            this.loginCallback(result.data);
          })
          .catch(() => {
            this.$eventBus.$emit(eventBusMessages.toast.CLOSE);
            this.loginResponseStatus = "accountNotRegistered";
            this.loginLoading = false;
          })
          .finally(() => {
            this.googlePending = false;
          });
      });
    },
    hideAlerts() {
      this.loginResponseStatus = "";
    },
    cleanUpTokens() {
      this.$store.commit(
        "authentication/setRefreshToken",
        "00000000-0000-0000-0000-000000000000"
      );
      localStorage.removeItem("should-remember-token");
      localStorage.removeItem("persistent-token");
      localStorage.removeItem("no");
    },
    loginCallback(tokenData) {
      this.$store
        .dispatch("authentication/authenticate", tokenData)
        .then(() => {
          this.$store.commit(
            "language/setSelectedLanguage",
            tokenData.languageCode
          );
          if (this.loginModel.rememberMe) {
            localStorage.setItem("should-remember-token", true);
            localStorage.setItem(
              "persistent-token",
              tokenData.persistenceToken
            );
            localStorage.setItem("no", tokenData.id);
          }
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else {
            this.$http.get("/playerSport/has").then((result) => {
              if (result.data) {
                this.$router.push({
                  name: "playerProfileFutureGames",
                  params: { profileId: tokenData.id },
                });
              } else {
                this.$router.push({
                  name: "playerProfileWelcome",
                  params: { profileId: tokenData.id },
                });
              }
            });
          }
        });
    },
  },
};
</script>
