import Vue from "vue";
import Vuex from "vuex";
import authentication from "./modules/authentication";
import language from "./modules/language";
import sportConfig from "./modules/sportConfig";
import searchResults from "./modules/searchResults";
import filterState from "./modules/filterState";
import group from "./modules/group";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ],
  modules: {
    authentication,
    language,
    sportConfig,
    searchResults,
    group,
    filterState
  }
});
