<template>
  <v-snackbar
    v-model="notification"
    :color="color"
    :multi-line="true"
    :timeout="timeout"
    :bottom="true"
    :right="true"
  >
    {{ message }}
    <v-btn dark flat @click="notification = false">Close</v-btn>
  </v-snackbar>
</template>

<script>
import eventBusMessages from "../../utils/eventBusMessages";
export default {
  name: "ToastNotification",
  data() {
    return {
      notification: false,
      timeout: 4000,
      color: "error",
      message: ""
    };
  },
  created() {
    this.$eventBus.$on(eventBusMessages.toast.NOTIFY, data => {
      this.notification = true;
      this.color = data.color;
      this.message = data.messages[0];
    });
    this.$eventBus.$on(eventBusMessages.toast.CLOSE, () => {
      this.notification = false;
    });
  },
  destroyed() {
    this.$eventBus.$off(eventBusMessages.toast.NOTIFY);
    this.$eventBus.$off(eventBusMessages.toast.CLOSE);
  }
};
</script>