import Vue from "vue";
import "./plugins/vuetify";
import "./plugins/facebook";
import Main from "./Main.vue";
import router from "./router";
import store from "./store";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import http from "./utils/http";
import * as VueGoogleMaps from "vue2-google-maps";
import VueAnalytics from "vue-analytics";
import "./styles/fonts/font-awesome-pro-5.8.2/css/all.min.css";
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import GAuth from "vue-google-oauth2";
import EventBus from "./utils/eventBus";
import progressBar from "./utils/progressBar";
import translations from "./utils/translations";
import VueTypedJs from "vue-typed-js";
import VueYoutube from "vue-youtube";

Vue.config.productionTip = false;
Vue.prototype.$http = http;
Vue.prototype.$eventBus = EventBus;
Vue.prototype.$progressBar = progressBar;
Vue.prototype.$translations = translations;

const gauthOption = {
  clientId:
    "660154566073-au6oc1bp02mjivpst68fege3tqmcqf0p.apps.googleusercontent.com",
  scope: "profile email",
  prompt: "select_account"
};

Vue.use(GAuth, gauthOption);
Vue.use(VueTypedJs);
Vue.use(VueYoutube);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAkm91U3D2ZgqIMdhSWIQho9wdxHEJ1yLg",
    libraries: "places",
    language: "en"
  }
});

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GOOGLETRACKID,
  router
});

new Vue({
  router,
  store,
  render: h => h(Main)
}).$mount("#app");
