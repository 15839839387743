<template>
  <v-btn
    :loading="loading"
    dark
    color="light-blue darken-4 elevation-0"
    large
    @click="signInWithFacebook"
    class="account-page__btn"
  >
    <v-icon dark center>fab fa-facebook</v-icon>
    <span :class="{ 'pad-left-35' : $store.getters['language/selectedLanguage'] === 'de' }">{{ label }} Facebook</span>
  </v-btn>
</template>

<script>
export default {
  name: "FacebookSignIn",
  props: {
    authCallback: {
      type: Function
    },
    loading: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    }
  },
  methods: {
    signInWithFacebook() {
      this.authCallback();
    }
  }
};
</script>

<style lang="scss" scoped>
.pad-left-35 {
  padding-left: 35px !important;
}
</style>