const state = {
  selectedLanguage: 'en'
};

const getters = {
  selectedLanguage: state => {
    return state.selectedLanguage;
  }
};

const mutations = {
  setSelectedLanguage(state, selectedLanguage) {
    state.selectedLanguage = selectedLanguage;
    this.dispatch("sportConfig/populateSportConfig", { root: true });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
